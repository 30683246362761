import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import LoginForm from './Forms/LoginForm';
import FrontpageGraphic from './Global/FrontpageGraphic';

function Frontpage() {
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const [loggedIn, setLoggedIn] = useState(true);

    useEffect(() => {
        if (!token) {
            setLoggedIn(false);
        }
    }, [token]);

    return (
        <>
            {token ? (
                <Row className="frontpage-container">
                    <FrontpageGraphic />

                    <Col xs={12} className="button-area-column">
                        <div className="button-area d-grid gap-3">
                            <Link to="/konehaku" className="btn btn-success btn-lg" type="button">
                                Konehaku
                            </Link>
                            <Link to="/konekanta" className="btn btn-success btn-lg" type="button">
                                Konekanta
                            </Link>
                            <Link
                                to="/varausseuranta"
                                className="btn btn-warning btn-lg"
                                type="button"
                            >
                                Varausseuranta
                            </Link>
                        </div>
                    </Col>
                </Row>
            ) : (
                <>
                    <FrontpageGraphic />
                    <LoginForm />
                </>
            )}
        </>
    );
}

export default Frontpage;
