import { Form, Button, Toast, ToastContainer, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import '../../css/Forms.scss';
import csvIcon from '../../img/file-csv-solid.svg';

const AddCSVForm = ({ cancel, uploadCSV, errors }) => {
    const [file, setFile] = useState(null);
    const [fetchBody, setFetchBody] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [show, setShow] = useState(false);
    /* FILE UPLOAD METHODS */

    /**
     * Handle uploaded file.
     * Add file URL to data state and add the file object to file state for validation
     * @param {object} e - Event object
     */
    const handleUpload = (e) => {
        setFileError(null);
        if (e.target.files[0]) {
            const uploadedFile = e.target.files[0];
            // Limit file size to 9MB
            if (uploadedFile.size > 9000000) {
                setFileError('Tiedoston maksimikoko on 9MB');
                setShow(true);
                return;
            }
            // // Limit file types to CSV and Excel files
            // if (!uploadedFile.type.match(/csv/i) || !uploadedFile.type.match(/spreadsheetml/i)) {
            //     setFileError('Tiedoston muoto on väärä');
            //     setShow(true);
            //     return;
            // }
            setFile(uploadedFile);
        }
    };

    /**
     * Handle file deletion.
     * Remove the file from the state.
     * @param {object} e - Event object
     */
    const handleFileDelete = (e) => {
        e.preventDefault();
        setFile(null);
        setFetchBody(null);
    };

    /**
     * Construct FormData when the file is uploaded
     */
    useEffect(() => {
        if (file) {
            const formData = new FormData();
            formData.append('uploaded_file', file);
            setFetchBody(formData);
        }
    }, [file]);

    /**
     * Show errors toast if errors are found
     */
    useEffect(() => {
        if (errors) {
            setFileError(errors);
            setShow(true);
        }
    }, [errors]);

    if (confirm) {
        return (
            <div className="add-csv-container h-100">
                <h1>Haluatko varmasti lähettää tiedoston?</h1>
                <div className="button-container">
                    <Button
                        className="send"
                        style={{ padding: '1em' }}
                        variant="success"
                        onClick={() => uploadCSV(fetchBody)}
                    >
                        Lähetä
                    </Button>

                    <Button
                        className="cancel"
                        style={{ padding: '1em' }}
                        variant="warning"
                        onClick={() => setConfirm(false)}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="add-csv-container h-100">
            <ToastContainer className="mb-4">
                <Toast show={show} onClose={() => setShow(false)} animation bg="danger">
                    <Toast.Header className="danger">
                        <strong className="me-auto text-primary">Virhe</strong>
                    </Toast.Header>
                    <Toast.Body>{fileError}</Toast.Body>
                </Toast>
            </ToastContainer>
            <Row className="align-items-center">
                <Col md="6" sm="12">
                    <Form className="add-machine-form mb-4">
                        <img
                            style={{ heigth: '40px', width: '40px', marginBottom: '2em' }}
                            src={csvIcon}
                            alt="CSV ikoni"
                        />
                        <label
                            className="btn btn-primary upload-button mb-2"
                            htmlFor="file-upload-field"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M384 352v64c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32v-64c0-17.67-14.33-32-32-32s-32 14.33-32 32v64c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96v-64c0-17.67-14.33-32-32-32S384 334.3 384 352zM201.4 9.375l-128 128c-12.51 12.51-12.49 32.76 0 45.25c12.5 12.5 32.75 12.5 45.25 0L192 109.3V320c0 17.69 14.31 32 32 32s32-14.31 32-32V109.3l73.38 73.38c12.5 12.5 32.75 12.5 45.25 0s12.5-32.75 0-45.25l-128-128C234.1-3.125 213.9-3.125 201.4 9.375z" />
                            </svg>
                            Valitse tiedosto
                            <input
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                id="file-upload-field"
                                style={{ display: 'none' }}
                                onChange={handleUpload}
                            />
                        </label>
                        <div className="file-info">
                            {file && <span onClick={handleFileDelete}>Close</span>}
                            <p className="text-muted">{file && file.name}</p>
                        </div>
                    </Form>
                </Col>
                <Col md="6" sm="12">
                    <p className="m-0">
                        Lisää koneita käyttäen CSV-tiedostoa. Voit ladata esimerkkitiedoston{' '}
                        <a
                            href={`${process.env.REACT_APP_BACKEND_BASE_URL}images/Veksla_import.xlsx`}
                        >
                            täältä.
                        </a>
                    </p>
                </Col>
            </Row>

            <div className="button-container">
                {file && (
                    <Button
                        className="send"
                        style={{ padding: '1em' }}
                        variant="success"
                        onClick={() => setConfirm(true)}
                    >
                        Lähetä
                    </Button>
                )}

                <Button
                    className="cancel"
                    style={{ padding: '1em' }}
                    variant="warning"
                    onClick={cancel}
                >
                    Peruuta
                </Button>
            </div>
        </div>
    );
};

AddCSVForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    uploadCSV: PropTypes.func.isRequired,
    errors: PropTypes.string,
};

AddCSVForm.defaultProps = {
    errors: null,
};

export default AddCSVForm;
