import { Button, Form, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useState } from 'react';
import '../../css/Forms.scss';
import spinner from '../../img/spinner.svg';
import showPass from '../../img/eye-regular.svg';
import hidePass from '../../img/eye-slash-regular.svg';

const NewPasswordForm = () => {
    const [formData, setFormData] = useState({ password: '' });
    const [formSent, setFormSent] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [resetError, setResetError] = useState(false);

    const { token } = useParams();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /** Try to login by posting user credentials to the /login endpoint.
     * If user is found, the endpoint return the information about the user
     * and an authorization token
     * @return {object} User object
     */
    function resetPassword() {
        const data = {
            token,
            email: formData.email,
            password: formData.password,
            password_confirmation: formData.password,
        };
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        };
        setLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/password/reset`, requestOptions)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    setFormSent(true);
                    setLoading(false);
                } else {
                    throw new Error();
                }
            })

            .catch((error) => {
                setLoading(false);
                setResetError(true);
            });
    }

    /** Validate form */
    const validate = () => {
        let errors = false;
        setErrorMsgs({});
        if (!formData.email) {
            setErrorMsgs((prevState) => ({ ...prevState, emailError: 'Täytä sähköpostiosoite' }));
            errors = true;
        }
        if (!formData.password) {
            setErrorMsgs((prevState) => ({ ...prevState, emailError: 'Anna salasana' }));
            errors = true;
        }
        if (formData.password.length < 8) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                emailError: 'Salasanan pitää olla vähintään 8 merkkiä pitkä',
            }));
            errors = true;
        }
        return !errors;
    };

    /** Handle form submit event */
    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            resetPassword();
        }
    };

    if (formSent) {
        return (
            <>
                <p>Salasana on vaihdettu ja voit kirjautua sisään.</p>
                <Link to="/kirjaudu" className="btn btn-success">
                    Kirjautuminen
                </Link>
            </>
        );
    }

    if (loading) {
        return <img src={spinner} alt="Ladataan" className="loading-spinner" />;
    }

    return (
        <Form className="mx-auto login-form" onSubmit={submitHandler}>
            <h1>Salasanan palautus</h1>
            <p>Anna uusi salasana.</p>
            {resetError && (
                <p>Annetulle sähköpostille ei ole pyydetty salasanan vaihtoa, tarkista osoite.</p>
            )}
            <Form.Group className="mb-3" controlId="email">
                <FloatingLabel label="Sähköpostiosoite">
                    <Form.Control
                        className={errorMsgs.emailError && 'error'}
                        placeholder="Sähköpostiosoite"
                        type="email"
                        autoComplete="username"
                        onChange={(e) => {
                            const val = e.target.value;
                            setFormData((prevState) => ({ ...prevState, email: val }));
                        }}
                    />
                </FloatingLabel>
                {errorMsgs.emailError && (
                    <div className="error-message">{errorMsgs.emailError}</div>
                )}
            </Form.Group>
            <InputGroup className="mb-2">
                <Form.Control
                    className={errorMsgs.passwordError && 'error'}
                    placeholder="Salasana"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="username"
                    onChange={(e) => {
                        const val = e.target.value;
                        setFormData((prevState) => ({ ...prevState, password: val }));
                    }}
                />
                <InputGroup.Text>
                    <img
                        className="toggle-password-icon"
                        type="button"
                        alt="näytä salasana"
                        onClick={togglePasswordVisibility}
                        src={showPassword ? showPass : hidePass}
                    />
                </InputGroup.Text>
            </InputGroup>
            {errorMsgs.passwordError && (
                <div className="error-message">{errorMsgs.passwordError}</div>
            )}
            <Button
                variant={formData.password.length > 7 && 'success'}
                disabled={!(formData.password.length > 7)}
                type="submit"
            >
                Vaihda salasana
            </Button>
        </Form>
    );
};

export default NewPasswordForm;
