// https://reactjs.org/docs/error-boundaries.html

import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, errorInfo);
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div className="error-boundary centered-container">
                <h1>Jokin meni pieleen.</h1>
                <br />
                Käynnistä sovellus tai lataa sivu uudelleen.
            </div>
        )
      }

      return this.props.children;
    }
  }
