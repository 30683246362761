// import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
// import { Badge } from 'react-bootstrap';
import { useInterval } from '../Hooks/Hooks';

const ActionPointBadge = () => {
    const [badgeNumber, setBadgeNumber] = useState(0);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const companyID = +localStorage.getItem('company_id'); // + converts the value to number
    const history = useHistory();

    // Fetch the available regions from the backend
    const getReservations = () => {
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/reservations`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 401) {
                        return history.push('/kirjaudu?action=logout');
                    }
                    if (!res.ok) {
                        throw Error(res.statusText);
                        // console.error(res.statusText, res);
                    }
                    return res;
                })
                .then((res) => res.json())
                // .then((res) => {
                //     console.log(res.data);
                //     return res;
                // })
                .then((res) => {
                    // Loop through the reservations
                    // - check if the status is open and it is made to the current users company
                    // - check if the status is 3 (accepted) and it is made to the current users company
                    // - check if the latest message is sent to the current users company and the status is approved, wip or waiting and the reservation is not made to current users company
                    let count = 0;
                    res.data.map((reservation) => {
                        if (reservation.status === 1 && reservation.reserved_from === true) {
                            count += 1;
                        }
                        if (reservation.status === 3 && reservation.reserved_from === true) {
                            count += 1;
                        }
                        if (
                            [3, 4, 5].includes(reservation.status) &&
                            reservation.messages.length &&
                            reservation.reserved_from === false
                        ) {
                            if (reservation.messages[0].to_company === companyID) {
                                count += 1;
                            }
                        }
                        return '';
                    });
                    setBadgeNumber(count);
                })
                .catch((error) => console.log(error));
        }
    };
    // Check reservations again if token has changed
    useEffect(() => {
        getReservations();
    }, [token]);

    // Check new reservations every 10 minutes (600000ms)
    useInterval(() => {
        getReservations();
    }, 600000);

    if (badgeNumber > 0) {
        return <span className="talhu-badge badge rounded-pill">{badgeNumber}</span>;
    }
    return <></>;
};

// ActionPointBadge.propTypes = {
//     number: PropTypes.number,
// };
// ActionPointBadge.defaultProps = {
//     number: 0,
// };

export default ActionPointBadge;
