import { Button, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const RentConfirmation = ({ submitContract, cancelContract, didSend, setActive }) => {
    const [confirmed, setConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        submitContract();
        setIsLoading(true);
    };

    const toggleActive = () => {
        setActive(false);
    }

    useEffect(() => {
        if (didSend) {
            setConfirmed(true);
        } else {
            setError(true);
        }
        setIsLoading(false);
    }, [didSend]);

    if (isLoading) {
        return (
            <div className="centered-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (!confirmed) {
        return (
            <div className="confirmation-container">
                <h1 className="text-primary">Haluatko varmasti lähettää alustavan varauksen?</h1>
                <div className="button-area d-grid gap-3">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button
                        className="btn btn-warning btn-lg"
                        type="button"
                        onClick={cancelContract}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    if (confirmed && didSend) {
        return (
            <div className="confirmation-container">
                <h1>Kiitos, alustava varaus on nyt lähetetty.</h1>
                <div className="button-area d-grid gap-3">
                    <Button className="btn btn-success btn-lg" type="button" onClick={toggleActive}>
                        Konehaku
                    </Button>
                    <Link to="/varausseuranta" className="btn btn-warning btn-lg" type="button">
                        Varausseuranta
                    </Link>
                </div>
            </div>
        );
    }
    if (error) {
        return <p>tapahtui virhe, yritä myöhemmin uudelleen</p>;
    }
};

export default RentConfirmation;
