import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import RentForm from './Forms/RentForm';

const EditReservation = ({ setShow, data, variant, showPopup }) => {
    const [reservationDetails, setReservationDetails] = useState(null);
    const [reservationSent, setReservationSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const sendReservation = (reservationData) => {
        setReservationDetails(reservationData);
    };
    const ReservationDetailsMarkup = () => {
        return (
            <div className="reservation-details">
                <h1>Alustava varaus</h1>
                <p className="text-muted">
                    {data.product.model} / {data.company.name} /{' '}
                    {data.product.taxonomies.regions?.[0].name}
                </p>
            </div>
        );
    };

    const backButton = () => {
        showPopup(false);
        setShow(false);
    };
    const handleSubmit = () => {
        setLoading(true);
        // EDIT RESERVATION
        if (variant === 'comment') {
            const body = {
                product_id: data.product.id,
                status: 4,
                start_time: reservationDetails.startDate,
                end_time: reservationDetails.endDate,
                price: reservationDetails.rentPrice,
                billing_period: reservationDetails.billingInterval,
                price_split: reservationDetails.priceSplit,
                location: reservationDetails.location,
                additional_info: reservationDetails.extraInfo,
                attachment: reservationDetails.file,
            };
            // const message = {
            //     reservation_id: data.id,
            //     from_company: localStorage.getItem('company_id'),
            //     to_company: data.company.id,
            //     message: reservationDetails.extraInfo,
            // };

            fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(body),
            })
                .then((res) => {
                    setLoading(false);
                    if (res.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
        if (variant === 'addNote') {
            const body = {
                product_id: data.product.id,
                start_time: reservationDetails.startDate,
                end_time: reservationDetails.endDate,
                price: reservationDetails.rentPrice,
                billing_period: reservationDetails.billingInterval,
                price_split: reservationDetails.priceSplit,
                location: reservationDetails.location,
                additional_info: reservationDetails.extraInfo,
                attachment: reservationDetails.file,
            };
            fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(body),
            })
                .then((res) => {
                    setLoading(false);
                    if (res.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
        // REMOVE RESERVATION
        if (variant === 'remove') {
            const body = {
                status: 6,
            };
            fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(body),
            })
                .then((res) => {
                    setLoading(false);
                    if (res.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
        // ACCEPT RESERVATION
        if (variant === 'accept') {
            const bodyReservation = {
                status: 3,
            };
            const bodyProduct = {
                status: 0,
                model: data.product.model,
                year: data.product.year,
            };
            Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(bodyReservation),
                }),
                fetch(`${process.env.REACT_APP_API_URL}/products/${data.product.id}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(bodyProduct),
                }),
            ])
                .then(([reservation, status]) => {
                    setLoading(false);
                    if (reservation.ok && status.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
        // MARK RESERVATION AS DELIVERED
        if (variant === 'delivered') {
            const todayDate = new Date().toISOString().slice(0, 10);
            const body = {
                status: 2,
                start_time: todayDate,
            };
            fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(body),
            })
                .then((res) => {
                    setLoading(false);
                    if (res.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
        // MARK RESERVATION AS RETURNED (FINISHED)
        if (variant === 'returned') {
            const todayDate = new Date().toISOString().slice(0, 10);
            const bodyReservation = {
                status: 7,
                end_time: todayDate,
            };
            const bodyProduct = {
                status: 1,
            };
            Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/reservations/${data.id}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(bodyReservation),
                }),
                fetch(`${process.env.REACT_APP_API_URL}/products/${data.product.id}`, {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: JSON.stringify(bodyProduct),
                }),
            ])
                .then(([reservation, status]) => {
                    setLoading(false);
                    if (reservation.ok && status.ok) {
                        setReservationSent(true);
                    } else {
                        throw Error(res.statusText);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    if (loading) {
        return (
            <div className="centered-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    if (reservationSent) {
        return (
            <div className="confirmation-container edit-sent">
                <ReservationDetailsMarkup />
                <h1 style={{ fontSize: '2.5em', fontWeight: 'bold' }} className="text-primary">
                    Kiitos, vastaus on nyt lähetetty.
                </h1>
                <div className="button-area d-grid gap-3 w-100 mb-4">
                    <Link style={{ padding: '1rem' }} className="btn btn-success" to="/konehaku">
                        Konehaku
                    </Link>
                    <Button className="btn btn-warning btn-lg" type="button" onClick={backButton}>
                        Varausseuranta
                    </Button>
                </div>
            </div>
        );
    }
    if (reservationDetails) {
        return (
            <div className="confirmation-container edit-reservation">
                <ReservationDetailsMarkup />
                <h1 className="text-primary">Haluatko varmasti lähettää vastauksen?</h1>
                <div className="button-area d-grid gap-3 w-100">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button className="btn btn-warning btn-lg" type="button" onClick={backButton}>
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    if (variant === 'accept') {
        return (
            <div className="confirmation-container">
                <ReservationDetailsMarkup />
                <h1 className="text-primary">Haluatko varmasti hyväksyä varauksen?</h1>
                <div className="button-area d-grid gap-3 w-100">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button
                        className="btn btn-warning btn-lg"
                        type="button"
                        onClick={() => showPopup(false)}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    if (variant === 'remove') {
        return (
            <div className="confirmation-container">
                <ReservationDetailsMarkup />
                <h1 className="text-primary">Haluatko varmasti poistaa varauksen?</h1>
                <div className="button-area d-grid gap-3 w-100">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button
                        className="btn btn-warning btn-lg"
                        type="button"
                        onClick={() => showPopup(false)}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    if (variant === 'comment') {
        return <RentForm sendForm={sendReservation} data={data} />;
    }
    if (variant === 'addNote') {
        return (
            <>
                <ReservationDetailsMarkup />
                <RentForm sendForm={sendReservation} data={data} addNote={variant} />
            </>
        );
    }
    if (variant === 'delivered') {
        return (
            <div className="confirmation-container edit-reservation">
                <ReservationDetailsMarkup />
                <h1 className="text-primary">Haluatko varmasti merkitä laitteen toimitetuksi?</h1>
                <div className="button-area d-grid gap-3 w-100">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button
                        className="btn btn-warning btn-lg"
                        type="button"
                        onClick={() => showPopup(false)}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    if (variant === 'returned') {
        return (
            <div className="confirmation-container edit-reservation">
                <ReservationDetailsMarkup />
                <h1 className="text-primary">Haluatko varmasti merkitä laitteen palautetuksi?</h1>
                <div className="button-area d-grid gap-3 w-100">
                    <Button className="btn btn-success btn-lg" type="button" onClick={handleSubmit}>
                        Kyllä
                    </Button>
                    <Button
                        className="btn btn-warning btn-lg"
                        type="button"
                        onClick={() => showPopup(false)}
                    >
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }
    return <></>;
};

EditReservation.propTypes = {
    /* Toggle popup visibility */
    setShow: PropTypes.func.isRequired,
    /* Reservation object */
    data: PropTypes.object.isRequired,
    /* Action type that needs to be performed on the reservation */
    variant: PropTypes.oneOf(['accept', 'comment', 'remove', 'delivered', 'returned', 'addNote'])
        .isRequired,
    /* Toggle popup visibility */
    showPopup: PropTypes.func.isRequired,
};

export default EditReservation;
