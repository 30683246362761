import { NavLink } from 'react-router-dom';
import ActionPointBadge from '../ActionPointBadge';

// ToDo: Add current-page check and add class to link item

const MenuItems = () => {
    return (
        <>
            <NavLink to="/konekanta" className="nav-link">
                Konekanta
            </NavLink>
            <NavLink to="/konehaku" className="nav-link">
                Konehaku
            </NavLink>
            <NavLink to="/varausseuranta" className="nav-link">
                Varausseuranta <ActionPointBadge />
            </NavLink>
        </>
    );
};

export default MenuItems;
