import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import Filter from './Filter';
import FoundResults from '../Global/FoundResults';

const SearchArea = ({ amount, setSearchTerm, setFilters, filters, variation }) => {
    const [regionData, setRegionData] = useState();
    const [categoryData, setCategoryData] = useState();
    const [manufacturerData, setManufacturerData] = useState();
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    const statuses = [
        { id: 0, name: 'Kaikki', slug: 'all' },
        { id: 1, name: 'Uudet varaukset', slug: 'new' },
        { id: 2, name: 'Toimitettu', slug: 'delivered' },
        { id: 3, name: 'Hyväksytty', slug: 'approved' },
        { id: 4, name: 'Kesken', slug: 'wip' },
        { id: 5, name: 'Odottaa vastausta', slug: 'waiting' },
        { id: 6, name: 'Peruutettu', slug: 'canceled' },
        { id: 7, name: 'Arkistoitu', slug: 'archived' },
    ];

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/regions?hide_empty=true`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setRegionData(res);
            });
    }, [token]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/product-categories?hide_empty=true`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setCategoryData(res);
            });
    }, [token]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/manufacturers?hide_empty=true`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setManufacturerData(res);
            })
            .catch((error) => console.log(error));
    }, [token]);

    return (
        <div className="search-container row">
            <div className="mobile-filters">
                <Filter
                    filters={filters}
                    setFilters={setFilters}
                    regionData={regionData}
                    manufacturerData={manufacturerData}
                    categoryData={categoryData}
                    variation={variation}
                    statuses={statuses}
                />
                <input
                    className="search-input"
                    type="text"
                    placeholder="Hae nimellä"
                    onChange={(event) => setSearchTerm(event.target.value)}
                />
            </div>
            <FoundResults
                amount={amount}
                filters={filters}
                setFilters={setFilters}
                setSearchTerm={setSearchTerm}
                regionData={regionData}
                manufacturerData={manufacturerData}
                categoryData={categoryData}
                variation={variation}
                statuses={statuses}
            />
        </div>
    );
};

SearchArea.propTypes = {
    amount: PropTypes.number.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    variation: PropTypes.string,
};
SearchArea.defaultProps = {
    variation: '',
};

export default SearchArea;
