import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Row, Button, Spinner } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
// import products from '../data/bookings.json';
import SingleItem from './SingleItem';
import AddMachine from './AddMachine';
import Popup from './Global/Popup';
import ChangeMachineStatus from './ChangeMachineStatus';
import EditMachine from './EditMachine';
import ReservationInfo from './ReservationInfo';
import ReservationInfoSidebar from './ReservationInfoSidebar';
// import { useFetch } from './Hooks/Hooks';

/**
 * Used to list all the products fetched from the backend
 */
const ItemsList = ({
    items,
    setItems,
    variation,
    searchTerm,
    filters,
    setFilteredProductCount,
}) => {
    /* States */
    // const [productsSelected, setProductsSelected] = useState([]);
    // const [products, setProducts] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [editMachine, setEditMachine] = useState(false);
    const [rerender, setRerender] = useState(0);
    const [reservation, setReservation] = useState(null);
    // Popup state
    const [popupActive, setPopupActive] = useState(false);
    // Reservation info sidebar visibility
    const [show, setShow] = useState(false);
    const { category, location, manufacturer, status, reservedFrom } = filters;
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const history = useHistory();

    const switchMachineStatus = (item) => {
        setChangeStatus(item);
    };

    const editMachineInfo = (item) => {
        setEditMachine(item);
    };

    const getReservationInfo = (data) => {
        setReservation(data);
        setShow(true);
    };

    /**
     * Open popup when user wants to edit machine status or info
     */
    useEffect(() => {
        if (editMachine || changeStatus) {
            setPopupActive(true);
        }
    }, [editMachine, changeStatus]);

    /**
     * Increment the rerender state by 1 to trigger the fetch to update the item list and clear popup content.
     * This function in called when the popup is closed and items have been added or updated.
     * @returns void
     */
    const reloadMachines = () => {
        setRerender((prevState) => prevState + 1);
        setEditMachine(false);
        setChangeStatus(false);
    };

    /**
     * If the popup is closed, reset the popup content to default and refetch items
     * TODO: only run when items have been added or updated to avoid unnecessary backend calls
     *
     * @see reloadMachines
     */
    useEffect(() => {
        if (!popupActive) {
            reloadMachines();
            // console.log('reloaded');
        }
    }, [popupActive]);
    useEffect(() => {
        if (!show) {
            reloadMachines();
            // console.log('reloaded 2');
        }
    }, [show]);

    /**
     * Component that is inserted in the Popup.js component to display content.
     * @returns {Component}
     */
    const PopupContent = () => {
        if (editMachine) {
            return <EditMachine item={editMachine} setPopupActive={setPopupActive} />;
        }
        if (changeStatus) {
            return <ChangeMachineStatus item={changeStatus} setPopupActive={setPopupActive} />;
        }

        if (variation === 'reservations') {
            return <ReservationInfo reservation={reservation} />;
        }
        if (variation === 'machines') {
            return <AddMachine reloadMachines={reloadMachines} setPopupActive={setPopupActive} />;
        }
        return 'Unknown variation, no content set';
    };
    const ListColumns = () => {
        if (variation === 'reservations') {
            return (
                <>
                    <div className="product-name">Nimi</div>
                    <div className="company-name">Yritys</div>
                    <div className="reservation-date">Päivämäärä</div>
                    <div className="reservation-status">Status</div>
                    <div className="company-location">Sijainti</div>
                </>
            );
        }
        if (variation === 'machines') {
            return (
                <>
                    <div className="product-name">Nimi</div>
                    <div className="product-manufactured-date">Vuosimalli</div>
                    <div className="company-location">Sijainti</div>
                    <div className="product-status">Varattavissa</div>
                </>
            );
        }
        return (
            <>
                <div className="product-name">Nimi</div>
                <div className="company-name">Yritys</div>
                <div className="company-location">Sijainti</div>
            </>
        );
    };

    /* Get products from the API */
    // const reservationsData = useFetch('reservations?sort=-created_at');
    // const machinesData = useFetch('/company/products');
    useEffect(() => {
        if (variation === 'reservations') {
            // setItems(reservationsData.data);
            // setError(reservationsData.setError);
            // setIsLoading(reservationsData.setIsLoading);
            fetch(`${process.env.REACT_APP_API_URL}/reservations?sort=-created_at`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 401) {
                        return history.push('/kirjaudu?action=logout');
                    }
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }
                    return res;
                })
                .then((res) => res.json())
                .then((data) => {
                    setItems(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(true);
                    setIsLoading(false);
                    console.log(error);
                });
        } else if (variation === 'machines') {
            console.log('ItemsList loaded');
            // console.log(machinesData);
            // setItems(machinesData.data);
            // setError(machinesData.setError);
            // setIsLoading(machinesData.setIsLoading);
            fetch(`${process.env.REACT_APP_API_URL}/company/products`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.status === 401) {
                        return history.push('/kirjaudu?action=logout');
                    }
                    if (!res.ok) {
                        throw Error(res.statusText);
                    }
                    return res;
                })
                .then((res) => res.json())
                .then((data) => {
                    setItems(data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(true);
                    setIsLoading(false);
                    console.log(error);
                });
        }
    }, [rerender]);
    // console.log(items.data);

    const activeFilterCount = () => {
        let count = 0;
        Object.keys(filters).forEach((item) => {
            count += filters[item].length;
        });
        return count;
    };

    const filteredItems = () => {
        if (items.data) {
            if (variation === 'reservations') {
                // const allProducts = products.data;
                const companyID = +localStorage.getItem('company_id'); // + converts the value to number
                // sort array by updated date

                const sortedData = items.data.sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    // Sort new orders first, then those with new messages and then rest by status and date
                    return (
                        (b.status === 1) - (a.status === 1) ||
                        (b.messages[0]?.to_company === companyID &&
                            [2, 3, 4, 5].includes(b.status)) -
                            (a.messages[0]?.to_company === companyID &&
                                [2, 3, 4, 5].includes(a.status)) ||
                        a.status - b.status ||
                        dateB - dateA
                    );
                });

                const filterByStatus = items.data.filter((item) => {
                    // return item.status.length && item.status.includes(status);
                    if (status.length > 0) {
                        return item.status === status[0];
                    }
                    return items.data; // return all items if no status filter set so the reserved from filter works
                });

                const filterByReservedFrom = filterByStatus.filter((item) => {
                    if (reservedFrom.length > 0) {
                        return reservedFrom.some((i) => item.reserved_from === i);
                    }
                    return item;
                });
                /**
                 * Combine filter arrays to one and remove duplicates if some filter is set.
                 * Otherwise return empty array.
                 */
                const outputFilters = [...new Set([...filterByReservedFrom])];

                const filterAllBySearchword = items.data.filter(
                    (item) =>
                        item.product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.company.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                const filterOutputBySearchword = outputFilters.filter(
                    (item) =>
                        item.product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.company.name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                if (searchTerm !== '' && activeFilterCount() !== 0) {
                    // console.log('searchterm and some filter are set');
                    setFilteredProductCount(filterOutputBySearchword.length);
                    return filterOutputBySearchword;
                }
                if (activeFilterCount() !== 0) {
                    // console.log('some filter is set');
                    setFilteredProductCount(outputFilters.length);
                    return outputFilters;
                }
                // console.log('filters are empty');
                setFilteredProductCount(filterAllBySearchword.length);
                return filterAllBySearchword;
            }
            if (variation === 'machines') {
                // const allProducts = products.data;
                const filterByCategory = items.data.filter((product) =>
                    category.some((i) =>
                        product.taxonomies['product-categories']?.some(
                            (productCategory) => productCategory.name === i
                        )
                    )
                );
                const filterByManufacturer = items.data.filter((product) =>
                    manufacturer.some((i) =>
                        product.taxonomies.manufacturers?.some((manu) => manu.name === i)
                    )
                );
                const filterByLocation = items.data.filter((product) =>
                    location.some((i) => product.taxonomies.regions?.some((region) => region.name === i))
                );

                /**
                 * Combine filter arrays to one and remove duplicates if some filter is set.
                 * Otherwise return empty array.
                 */
                const outputFilters = [
                    ...new Set([...filterByCategory, ...filterByManufacturer, ...filterByLocation]),
                ];

                const filterAllBySearchword = items.data.filter(
                    (product) =>
                        product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        product.taxonomies.manufacturers?.some((manu) =>
                            manu.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                );
                const filterOutputBySearchword = outputFilters.filter(
                    (product) =>
                        product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        product.taxonomies.manufacturers?.some((manu) =>
                            manu.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                );

                if (searchTerm !== '' && activeFilterCount() !== 0) {
                    // console.log('searchterm and some filter are set');
                    setFilteredProductCount(filterOutputBySearchword.length);
                    return filterOutputBySearchword;
                }
                if (activeFilterCount() !== 0) {
                    // console.log('some filter is set');
                    setFilteredProductCount(outputFilters.length);
                    return outputFilters;
                }
                // console.log('filters are empty');
                setFilteredProductCount(filterAllBySearchword.length);
                return filterAllBySearchword;
            }
            // const outputFilters = filterByStatus;

            // const filterAllBySearchword = items.data.filter(
            //     (item) =>
            //         item.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
            //         item.manufacturer.toLowerCase().includes(searchTerm.toLowerCase())
            // );
            // const filterOutputBySearchword = outputFilters.filter(
            //     (item) =>
            //         item.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
            //         item.manufacturer.toLowerCase().includes(searchTerm.toLowerCase())
            // );

            // if (searchTerm !== '' && activeFilterCount() !== 0) {
            //     // console.log('searchterm and some filter are set');
            //     setFilteredProductCount(filterOutputBySearchword.length);
            //     return filterOutputBySearchword;
            // }
            // if (activeFilterCount() !== 0) {
            //     // console.log('some filter is set');
            //     setFilteredProductCount(outputFilters.length);
            //     return outputFilters;
            // }
            // // console.log('filters are empty');
            // setFilteredProductCount(filterAllBySearchword.length);
            // return filterAllBySearchword;
            return outputFilters;
        }
        return '';
    };

    // console.log(items.data);

    if (isLoading) {
        return (
            <div className="centered-container">
                <h1>Ladataan {variation === 'reservations' ? 'varauksia...' : 'koneita...'}</h1>
                <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    if (isError) {
        return (
            <div className="centered-container">
                <h1>Tapahtui virhe, yritä myöhemmin uudelleen</h1>
            </div>
        );
    }
    if (filteredItems().length === 0 && variation === 'machines') {
        return (
            <div className="centered-container">
                <h1>Yhtään konetta ei löytynyt</h1>
                <div className="next-button-area next-button-area--active">
                    <Button
                        variant="success"
                        size="lg"
                        style={{ width: '100%' }}
                        onClick={() => setPopupActive(true)}
                    >
                        Lisää koneita
                    </Button>
                </div>
                <Popup active={popupActive} setActive={setPopupActive} content={<PopupContent />} />
            </div>
        );
    }
    if (filteredItems().length === 0) {
        return (
            <div className="centered-container">
                <h1>Yhtään varausta ei löytynyt</h1>
            </div>
        );
    }
    return (
        <>
            <Row className={`products-list products-list--${variation}`}>
                <div className="products-list__columns">
                    <ListColumns />
                </div>
                {filteredItems().map((item) => (
                    <SingleItem
                        setActive={setPopupActive}
                        key={item.id}
                        result={item}
                        variation={variation}
                        switchMachineStatus={switchMachineStatus}
                        editMachine={editMachineInfo}
                        setReservation={getReservationInfo}
                    />
                ))}
                {variation === 'machines' && (
                    <div className="next-button-area next-button-area--active">
                        <Button variant="success" size="lg" onClick={() => setPopupActive(true)}>
                            Lisää kone
                        </Button>
                    </div>
                )}
            </Row>
            <Popup active={popupActive} setActive={setPopupActive} content={<PopupContent />} />
            <ReservationInfoSidebar show={show} setShow={setShow} reservationInfo={reservation} />
        </>
    );
};

ItemsList.propTypes = {
    /** When products are selected and user clicks the next button, pass the product objects up */
    items: PropTypes.object,
    setItems: PropTypes.func.isRequired,
    variation: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired,
    setFilteredProductCount: PropTypes.func.isRequired,
};
ItemsList.defaultProps = {
    items: {},
};

export default ItemsList;
