import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import PropTypes from 'prop-types';

/** Component for a single product, used in ProductList.js */
const SingleItem = ({
    result,
    setActive,
    variation,
    switchMachineStatus,
    editMachine,
    setReservation,
}) => {
    // console.log(result);

    // When the button is clicked, open popup
    // const openPopup = () => setActive(true);
    const [showInfo, setShowInfo] = useState(false);
    const showExtraInfo = () => {
        setShowInfo((prevCheck) => !prevCheck);
    };

    const ItemName = () => {
        if (variation === 'reservations') {
            return result.product.model || 'N/A';
        }
        if (variation === 'machines') {
            const manufacturer = result.taxonomies.manufacturers ? result.taxonomies.manufacturers[0].name : '';
            return `${manufacturer} ${result?.model}`;
        }
        return 'Null';
    };
    const statuses = [
        { id: 0, name: 'Kaikki', slug: 'all' },
        { id: 1, name: 'Uusi varaus', slug: 'new' },
        { id: 2, name: 'Toimitettu', slug: 'delivered' },
        { id: 3, name: 'Hyväksytty', slug: 'approved' },
        { id: 4, name: 'Kesken', slug: 'wip' },
        { id: 5, name: 'Odottaa vastausta', slug: 'waiting' },
        { id: 6, name: 'Peruutettu', slug: 'canceled' },
        { id: 7, name: 'Arkistoitu', slug: 'archived' },
    ];
    const statusID = result.status;
    const reservedFrom = result.reserved_from;
    const companyID = +localStorage.getItem('company_id'); // + converts the value to number
    const itemClassName = () => {
        if (variation === 'reservations') {
            let itemClass = 'single-item--reservation';
            if (reservedFrom === true) {
                itemClass += ' outgoing';
            } else {
                itemClass += ' incoming';
            }
            itemClass += ` status-${statuses[statusID].slug}`;
            if ([3, 4, 5].includes(result.status) && result.messages.length) {
                if (result.messages[0].to_company === companyID) {
                    itemClass += ' has-new-messages';
                }
            }
            return itemClass;
        }
        return '';
    };
    // const actionPointBadge = () => {
    //     if (variation === 'reservations') {
    //         let itemClass = 'single-item--reservation';
    //         if (reservedFrom === true) {
    //             itemClass += ' outgoing';
    //         } else {
    //             itemClass += ' incoming';
    //         }
    //         itemClass += ` status-${statuses[statusID].slug}`;
    //         if ([3, 4, 5].includes(result.status) && result.messages.length) {
    //             if (result.messages[0].to_company === companyID) {
    //                 itemClass += ' has-new-messages';
    //             }
    //         }
    //         return itemClass;
    //     }
    //     return '';
    // };

    /*          RESERVATION RENDER          */
    if (variation === 'reservations') {
        return (
            <div className={`single-item ${itemClassName()}`}>
                <div className="item-details">
                    <div className="item-name">
                        <ItemName />
                    </div>
                    <div className="company-name">
                        {result.company.id === parseInt(localStorage.getItem('company_id'), 10)
                            ? result.customer_name
                            : result.company.name}
                    </div>
                    <div className="reservation-date">
                        {new Date(result.start_time).toLocaleDateString('fi-FI')}
                    </div>
                    <div className={`reservation-status ${statuses[statusID].slug}`}>
                        {statuses[statusID].name}
                    </div>

                    <div className="company-location">{result.taxonomies.regions?.[0].name}</div>
                </div>
                <Button onClick={() => setReservation(result)}>
                    Lisätiedot
                    {result.status === 1 && result.reserved_from === true && (
                        <span className="talhu-badge badge rounded-pill">1</span>
                    )}
                    {result.status === 3 && result.reserved_from === true && (
                        <span className="talhu-badge badge rounded-pill">1</span>
                    )}
                    {[3, 4, 5].includes(result.status) &&
                        result.messages.length &&
                        result.messages[0].to_company === companyID &&
                        result.reserved_from === false && (
                            <span className="talhu-badge badge rounded-pill">1</span>
                        )}
                </Button>
            </div>
        );
    }
    /*          MACHINES RENDER          */
    if (variation === 'machines') {
        return (
            <div className={`single-item ${itemClassName()}`}>
                <div
                    className={`item-details ${result.additional_info ? 'has-extra-info' : ''} ${
                        showInfo ? 'open' : 'closed'
                    }`}
                    onClick={showExtraInfo}
                    onKeyPress={showExtraInfo}
                    role="button"
                    tabIndex={0}
                >
                    <div className="item-name">
                        <ItemName />
                    </div>
                    <div className="item-year">{result.year ? result.year : 'N/A'}</div>
                    <div className="company-location">
                        {result.taxonomies.regions ? result.taxonomies.regions[0].name : 'N/A'}
                    </div>
                </div>
                {!showInfo ? (
                    <Form.Switch
                        checked={result.status}
                        className="button"
                        onClick={() => {
                            switchMachineStatus(result);
                        }}
                        readOnly
                    />
                ) : (
                    <Button variant="warning" onClick={() => editMachine(result)}>
                        Muokkaa
                    </Button>
                )}
                {result.additional_info && (
                    <div className={`extra-details ${showInfo ? 'show' : ''}`}>
                        <div className="wrapper">{result.additional_info}</div>
                    </div>
                )}
            </div>
        );
    }
    return '';
};

SingleItem.propTypes = {
    /** Object that contains the information about the product */
    result: PropTypes.object.isRequired,
    variation: PropTypes.string.isRequired,
    setActive: PropTypes.func.isRequired,
    setReservation: PropTypes.func.isRequired,
    switchMachineStatus: PropTypes.func.isRequired,
    editMachine: PropTypes.func.isRequired,
};
// SingleItem.defaultProps = {};

export default SingleItem;
