import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';

const ChangeMachineStatus = ({ item, setPopupActive }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    const changeStatus = () => {
        const status = item.status ? 0 : 1;
        const body = {
            status,
            // model: item.model,
            // year: item.year,
        };
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/products/${item.id}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setPopupActive(false);
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
                console.log(err);
            });
    };
    if (loading) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    position: 'absolute',
                    transform: 'translate(50%, -50%)',
                    top: '50%',
                    right: '50%',
                }}
            >
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    if (error) {
        return <p>Tapahtui virhe, yritä myöhemmin uudelleen</p>;
    }
    return (
        <div className="container text-center add-machine-popup-container">
            <div className="text-container">
                <h1>Oma konekanta</h1>
                <p className="text-muted">
                    {item.taxonomies.manufacturers?.[0].name} {item.model}
                </p>
            </div>
            <div className="text-container">
                <h1 style={{ fontWeight: 'bold', fontSize: '2.5em' }}>
                    Haluatko varmasti muuttaa statuksen?
                </h1>
            </div>
            <div className="button-area d-grid gap-3">
                <Button variant="success" onClick={changeStatus}>
                    Kyllä
                </Button>
                <Button variant="warning" onClick={() => setPopupActive(false)}>
                    Peruuta
                </Button>
            </div>
        </div>
    );
};

ChangeMachineStatus.propTypes = {
    /** The product object, determines which product status is beign changed */
    item: PropTypes.object.isRequired,
    /** Function that controls the popup window visibility, used to close the popup after status change */
    setPopupActive: PropTypes.func.isRequired,
};

export default ChangeMachineStatus;
