import './css/App.scss';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Container, Toast } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { retrieveToken, onMessageListener } from './firebaseInit';
import Frontpage from './components/Frontpage';
import Register from './components/Register';
import Header from './components/Global/Header/Header';
import Searchpage from './components/Searchpage';
import Profile from './components/Profile';
import ProfileInfo from './components/ProfileInfo';
import NotFound from './components/NotFound';
import Login from './components/Login';
import ListPage from './components/ListPage';
import ErrorBoundary from './components/Global/ErrorBoundary';

require('dotenv').config();

function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [tokenFound, setTokenFound] = useState(false);
    const [notificationToken, setNotificationToken] = useState(null);

    const handleLogOut = () => {
        removeCookie('token', { path: '/' });
    };

    onMessageListener();

    useEffect(() => {
        let data;

        async function tokenFunc() {
            data = await retrieveToken(setTokenFound, setNotificationToken);
            if (data) {
                console.log('Token is ', data);
            }
            return data;
        }

        tokenFunc();
    }, [setTokenFound]);

    useEffect(() => {
        if (cookies.token && notificationToken) {
            const body = {
                token: notificationToken,
            };
            fetch(`${process.env.REACT_APP_API_URL}/store-token`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${cookies.token}`,
                },
                body: JSON.stringify(body),
            });
        }
    }, [cookies.token, notificationToken]);

    // A centralized route config
    const routes = [
        {
            path: '/',
            exact: true,
            title: () => '',
            content: () => <Frontpage cookies={cookies} setCookie={setCookie} />,
        },
        {
            path: '/konehaku',
            title: () => 'Konehaku',
            content: () => <Searchpage />,
        },
        {
            path: '/profiili',
            title: () => 'Profiili',
            content: () => <Profile cookies={cookies} />,
        },
        {
            path: '/omat-tiedot',
            title: () => 'Omat tiedot',
            content: () => <ProfileInfo />,
        },
        {
            path: '/konekanta',
            title: () => 'Oma konekanta',
            content: () => <ListPage variation="machines" />,
        },
        {
            path: '/rekisteroidy',
            title: () => 'Rekisteröidy',
            content: () => <Register />,
        },
        {
            path: '/kirjaudu',
            title: () => 'Kirjaudu',
            content: () => <Login handleLogOut={handleLogOut} />,
        },
        {
            path: '/varausseuranta',
            title: () => 'Varausseuranta',
            content: () => <ListPage variation="reservations" />,
        },
        {
            path: '*',
            title: () => '404',
            content: () => <Redirect to="/" />,
        },
    ];

    // Route config for unauthenticated users
    const unauthRoutes = [
        {
            path: '/kirjaudu',
            title: () => 'Kirjaudu',
            content: () => <Login handleLogOut={handleLogOut} variant="login" />,
        },
        {
            path: '/salasanan-palautus/uusi-salasana/:token',
            title: () => 'Salasanan palautus',
            content: () => (
                <Login variant="newPassword" setCookie={setCookie} removeCookie={removeCookie} />
            ),
        },
        {
            path: '/salasanan-palautus',
            title: () => 'Salasanan palautus',
            content: () => (
                <Login variant="reset" setCookie={setCookie} removeCookie={removeCookie} />
            ),
        },
        {
            path: '/rekisteroidy',
            title: () => 'Rekisteröidy',
            content: () => <Register />,
        },
        {
            path: '*',
            title: () => '',
            content: () => <Redirect to="/kirjaudu" />,
        },
    ];
    const ButtonComponent = () => {
        throw Error('error!');
        return <h1>Jeejee</h1>;
    };
    if (!cookies.token) {
        return (
            <Router>
                <Header routes={unauthRoutes} />
                <Container fluid className="content-wrapper">
                    <Switch>
                        {unauthRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.content />
                            </Route>
                        ))}
                    </Switch>
                </Container>
            </Router>
        );
    }
    return (
        <Router>
            <Header routes={routes} />
            <Container fluid className="content-wrapper">
                <Switch>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.path} exact={route.exact}>
                            <ErrorBoundary>
                                <route.content />
                            </ErrorBoundary>
                        </Route>
                    ))}
                </Switch>
            </Container>
        </Router>
    );
}

export default App;
