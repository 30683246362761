import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, BrowserRouter as Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import '../../../css/Header.scss';
import HeaderLogo from './HeaderLogo';
import MenuItems from './MenuItems';
import UserMenuItems from './UserMenuItems';
import ErrorBoundary from '../ErrorBoundary';

/** Header component, that is displayed on every page */
const Header = ({ routes }) => {
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    return (
        <Navbar expand="lg" variant="dark">
            <Container fluid>
                <ErrorBoundary>
                    <Link to="/" className="navbar-brand">
                        <HeaderLogo />
                    </Link>
                    <Nav>{token && <MenuItems />}</Nav>
                    <Switch>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <h1 className="page-title">
                                    <route.title />
                                </h1>
                            </Route>
                        ))}
                    </Switch>
                    {token && <UserMenuItems />}
                </ErrorBoundary>
            </Container>
        </Navbar>
    );
};

Header.propTypes = {
    /** Display the current page in the middle of the header */
    routes: PropTypes.array.isRequired,
};

export default Header;
