import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ActionPointBadge from './Global/ActionPointBadge';

const Profile = () => {
    const profileImg = localStorage.getItem('company_logo');
    const companyName = localStorage.getItem('company_name');
    const userName = localStorage.getItem('user_name');

    return (
        <Container className="myaccount-container">
            <Row className="profile-header">
                <Col>
                    <div
                        className="profile-image"
                        style={{ backgroundImage: `url(${profileImg})` }}
                    />
                    <div className="profile-info">
                        <h5 className="company-name">{companyName}</h5>
                        <div className="user-name">{userName}</div>
                    </div>
                </Col>
            </Row>
            <Row className="profile-link-area">
                <Col>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Link to="/konekanta">Oma konekanta</Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/konehaku">Konehaku</Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/varausseuranta">
                                Varausseuranta <ActionPointBadge />
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/omat-tiedot">Omat tiedot</Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/kirjaudu?action=logout">Kirjaudu ulos</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
