import {
    FloatingLabel,
    Form,
    Button,
    Row,
    Col,
    Toast,
    ToastContainer,
    InputGroup,
} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import kunnat from '../../data/kunnat.json';
import showPass from '../../img/eye-regular.svg';
import hidePass from '../../img/eye-slash-regular.svg';

const RegisterForm = ({ edit, userdata }) => {
    const [data, setData] = useState({
        company_logo: userdata.company_logo || '',
        user_notification_email: false,
        user_notification_push: false,
    });
    const errorMsgsInitState = {};
    const [errorMsgs, setErrorMsgs] = useState(errorMsgsInitState);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);
    // const [formSent, setFormSent] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [imagePreview, setImagePreview] = useState(data.company_logo);
    const [showPassword, setShowPassword] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    // console.log(userdata);
    // console.log(data);

    const toggleShowSuccess = () => setShowSuccess(!showSuccess);
    const toggleShowError = () => setShowError(!showError);
    const toggleShowFormErrors = () => setShowFormErrors(!showFormErrors);

    /**
     * Function to toggle password field visibility.
     * Changes field type between text and password.
     */
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /**
     * VAT number verification function. Checks the supplied VAT number
     * against the finnish business ID verification algorithm
     * @param {string} vat - in form NNNNNNN-T
     * @returns {boolean} Returns true if VAT is valid
     */
    const validateVAT = (vat) => {
        const oldVATRegex = /^[0-9]{6}-[0-9]{1}/;
        const VATRegex = /^[0-9]{7}-[0-9]{1}/;

        // Check if VAT is in old format and transform it to the new format
        // by adding 0 to the beginning
        if (vat.match(oldVATRegex)) {
            vat = `0${vat}`;
        }

        // Check if VAT is in correct format
        if (!vat.match(VATRegex)) {
            return false;
        }

        // Split the VAT to array of numbers and the checksum
        const fields = vat.split('-');
        const id = fields[0].split('');
        const checksum = fields[1];

        // Run the VAT number through the validation algorithm
        // https://tarkistusmerkit.teppovuori.fi/tarkmerk.htm#y-tunnus2
        const multipliers = [7, 9, 10, 5, 8, 4, 2];
        let totalCount = 0;
        for (let i = 0; i < multipliers.length; i += 1) {
            totalCount += multipliers[i] * id[i];
        }

        const remainder = totalCount % 11;

        if (remainder === 1) {
            return false;
        }
        if (remainder === 0) {
            return remainder === checksum;
        }
        return parseInt(checksum, 10) === 11 - remainder;
    };

    /**
     * Frontend form validation function. Form is only sent,
     * if all fields are valid. If errors are found, error messages are
     * added to the errorMsgs state object and displayd under the corresponding
     * field
     * @returns {boolean} returns true if the form is valid
     */
    const validate = () => {
        setErrorMsgs(errorMsgsInitState); // clear error messages
        let errors = false; // track if errors are found
        if (file) {
            // If file type is not an image, give an error
            if (!/image\/[a-z]*/.test(file.type)) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    companyLogoError: 'Logo ei ole kuvatiedosto',
                }));
                errors = true;
            }
        }
        if (!edit) {
            if (!data.user_email) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    personEmailError: 'Sähköposti on pakollinen',
                }));
                errors = true;
            } else if (!data.user_email.match(/^\S+@\S+\.\S+$/)) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    personEmailError: 'Sähköposti on väärässä muodossa',
                }));
                errors = true;
            }
        }
        if (!data.user_name) {
            setErrorMsgs((prevState) => ({ ...prevState, personNameError: 'Nimi on pakollinen' }));
            errors = true;
        }
        if (!data.user_phone) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                personPhoneError: 'Puhelinnumero on pakollinen',
            }));
            errors = true;
        }
        if (!data.company_email) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyEmailError: 'Sähköposti on pakollinen',
            }));
            errors = true;
        } else if (!data.company_email.match(/^\S+@\S+\.\S+$/)) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyEmailError: 'Sähköposti on väärässä muodossa',
            }));
            errors = true;
        }
        if (!data.company_phone) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyPhoneError: 'Puhelinnumero on pakollinen',
            }));
            errors = true;
        }
        if (!data.company_name) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyNameError: 'Yrityksen nimi on pakollinen',
            }));
            errors = true;
        }
        if (!data.company_streetaddress) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyAddressError: 'Yrityksen osoite on pakollinen',
            }));
            errors = true;
        }
        if (!data.company_zip) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyZipError: 'Postinumero on pakollinen',
            }));
            errors = true;
        }
        if (!data.company_postal) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyCityError: 'Kaupunki on pakollinen',
            }));
            errors = true;
        }
        if (data.comapny_description && data.company_description.length >= 400) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                companyDescriptionError: 'Kuvausteksti saa olla enintään 400 merkkiä pitkä.',
            }));
            errors = true;
        }
        if (!edit) {
            if (!data.company_businessid) {
                setErrorMsgs((prevState) => ({ ...prevState, vatError: 'Y-tunnus on pakollinen' }));
                errors = true;
            } else if (!validateVAT(data.company_businessid)) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    vatError: 'Y-tunnus on väärässä muodossa',
                }));
                errors = true;
            }

            if (!data.user_password) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    personPasswordError: 'Salasana on pakollinen',
                }));
                errors = true;
            } else if (data.user_password.length < '8') {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    personPasswordError: 'Salasanan on oltava vähintään 8 merkkiä pitkä',
                }));
                errors = true;
            }
        }
        if (errors) {
            setShowFormErrors(true);
            return false;
        }
        return true;
    };

    /**
     * Form change handling function. When a form field changes, the field
     * value is saved to the data state.
     * @param {object} e - Event object where the values are retrieved from
     */
    const handleChange = (e) => {
        const key = e.target.name;
        let val;
        if (e.target.type === 'checkbox') {
            val = e.target.checked;
        } else {
            val = e.target.value;
        }
        setData((prevState) => ({ ...prevState, [key]: val }));
    };

    useEffect(() => {
        if (edit && userdata) {
            const {
                company_logo,
                company_name,
                company_email,
                company_phone,
                company_description,
                company_streetaddress,
                company_zip,
                company_postal,
                company_businessid,
                user_name,
                user_email,
                user_phone,
            } = userdata;

            setData({
                company_logo,
                company_name,
                company_email,
                company_phone,
                company_description,
                company_streetaddress,
                company_zip,
                company_postal,
                company_businessid,
                user_name,
                user_email,
                user_phone,
            });
        }
    }, []);

    const saveDataToLocalStorage = (result) => {
        Object.keys(result).map((key) => localStorage.setItem(key, result[key]));
    };

    /**
     * Function that sends an HTTP request to the backend to register
     * a new user and company.
     */
    const register = () => {
        // Set form data to fetchBody const and remove passwordConfirm
        fetch(`${process.env.REACT_APP_API_URL}/register`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                // console.log(response);
                if (!response.ok) {
                    // If the registration was succesful, show the confirmation alert at the bottom of the page
                    throw response;
                } else {
                    setShowSuccess(true);
                }
            })
            .catch((error) => {
                if (error.status === 422) {
                    setErrorMsgs((prevState) => ({
                        ...prevState,
                        registerError: 'Yhteyshenkilö tai yritys on jo rekisteröity.',
                    }));
                    setShowError(true);
                }
            });
    };

    /**
     * Function to edit existing user and company information
     */
    const editUser = (data) => {
        // Set form data to fetchBody const and remove passwordConfirm
        fetch(`${process.env.REACT_APP_API_URL}/user`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                // console.log(response);
                if (response.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!response.ok) {
                    // If the registration was succesful, show the confirmation alert at the bottom of the page
                    throw response;
                } else {
                    setShowSuccess(true);
                    saveDataToLocalStorage(data);
                }
            })
            .catch((error) => {
                if (error.status === 422) {
                    setErrorMsgs((prevState) => ({
                        ...prevState,
                        registerError: 'Tietojen muuttaminen epäonnistui.',
                    }));
                    setShowError(true);
                }
            });
    };

    /** Handle submitted form.
     * If validate() returns true, form is valid and can be sent
     * @see {@link validate}
     * @see {@link editUser}
     * @see {@link register}
     * @param {object} e - Event object
     */
    const submitHandler = (e) => {
        e.preventDefault();

        const isValid = validate();
        if (isValid && data) {
            if (edit) {
                // Delete user_email and businessid from sent data so the API call goes through
                delete data.user_email;
                delete data.company_businessid;
                // console.log(data);
                editUser(data);
            } else {
                register();
            }
        }
    };

    /* FILE UPLOAD METHODS */

    /**
     * Handle uploaded file.
     * Add file URL to data state and add the file object to file state for validation
     * @param {object} e - Event object
     */
    const handleUpload = (e) => {
        if (e.target.files[0]) {
            if (e.target.files[0].size > 5000000) {
                setFileError('Kuvatiedoston maksimikoko on 5kt');
            } else {
                const imageUrl = URL.createObjectURL(e.target.files[0]);
                setImagePreview(imageUrl);
                setFile(e.target.files[0]);
                setFileError(null);
            }
        }
    };
    /** When an image is uploaded, convert the blob to Base64 to be saved in the backend */
    useEffect(() => {
        /* Check if the file state is present */
        if (file) {
            const reader = new FileReader();
            const base64Regex = /,(.*)/;
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                console.log(reader.result);
                setData((prevState) =>
                    // Use regex to strip the unnecessary characters and leave just the base64 string
                    ({ ...prevState, company_logo: reader.result.match(base64Regex)[1] })
                );
            };
        }
    }, [file]);

    /**
     * Handle logo deletion.
     * Remove the file from the state.
     * @param {object} e - Event object
     */
    const handleLogoDelete = (e) => {
        e.preventDefault();
        setData((prevState) => ({ ...prevState, company_logo: '' }));
        setFile(null);
        setImagePreview(null);
    };

    if (showSuccess) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    position: 'absolute',
                    transform: 'translate(50%, -50%',
                    top: '50%',
                    right: '50%',
                }}
            >
                {edit ? (
                    <h1>Tietojen muokkaaminen onnistui</h1>
                ) : (
                    <>
                        <h1>Rekisteröinti onnistui</h1>
                        <p>
                            Vahvista tunnuksesi sähköpostiin saapuvasta linkistä. Jos sähköposti ei
                            saavu, tarkista roskaposti ja odota vähintään 10 minuuttia ennen kuin
                            rekisteröit uudelleen.
                        </p>
                    </>
                )}
            </div>
        );
    }

    return (
        <Form noValidate className="register-form" onSubmit={submitHandler}>
            <h2 className="mb-4 mt-4">Yrityksen tiedot</h2>
            <label className="logo-upload form-label" htmlFor="logo-input">
                {data.company_logo ? (
                    <>
                        <img src={imagePreview} alt="logo" />
                        <span onClick={handleLogoDelete}>Close</span>
                    </>
                ) : (
                    <p className="text-muted">Logo</p>
                )}
                <input
                    id="logo-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onChange={handleUpload}
                />
            </label>
            <Form.Group controlId="formFile" className="mb-3">
                {/* <Form.Label className="logo-upload">
                    {data.company_logo ? (
                        <>
                            <img src={imagePreview} alt="logo" />
                            <span onClick={handleLogoDelete}>Close</span>
                        </>
                    ) : (
                        <p className="text-muted">Logo</p>
                    )}
                    <Form.Control
                        className="d-none"
                        type="file"
                        onChange={handleUpload}
                        accept="image/png, image/jpeg"
                    />
                </Form.Label> */}
                {fileError && <div className="error-message">{fileError}</div>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="companyName">
                {/* <Form.Label>Yrityksen nimi *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Yrityksen nimi">
                    <Form.Control
                        className={errorMsgs.companyNameError && 'error'}
                        name="company_name"
                        type="text"
                        required
                        placeholder="Yrityksen nimi"
                        value={data.company_name}
                        onChange={handleChange}
                    />
                    {errorMsgs.companyNameError && (
                        <div className="error-message">{errorMsgs.companyNameError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="companyAdress">
                {/* <Form.Label>Osoite *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Katuosoite">
                    <Form.Control
                        className={errorMsgs.companyAddressError && 'error'}
                        name="company_streetaddress"
                        type="text"
                        required
                        placeholder="Katuosoite"
                        value={data.company_streetaddress}
                        onChange={handleChange}
                    />
                    {errorMsgs.companyAddressError && (
                        <div className="error-message">{errorMsgs.companyAddressError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
                <Row className="g-2">
                    <Col sm>
                        <FloatingLabel controlId="floatingInput" label="Postinumero">
                            <Form.Control
                                className={errorMsgs.companyZipError && 'error'}
                                name="company_zip"
                                type="text"
                                required
                                placeholder="Postinumero"
                                value={data.company_zip}
                                onChange={handleChange}
                            />
                            {errorMsgs.companyZipError && (
                                <div className="error-message">{errorMsgs.companyZipError}</div>
                            )}
                        </FloatingLabel>
                    </Col>
                    <Col sm>
                        <FloatingLabel controlId="floatingSelect" label="Kaupunki">
                            <Form.Select
                                className={errorMsgs.companyCityError && 'error'}
                                name="company_postal"
                                type="text"
                                required
                                onChange={handleChange}
                            >
                                <option>---</option>
                                {kunnat.map((kunta) => (
                                    <option
                                        key={kunta}
                                        value={kunta}
                                        selected={data.company_postal === kunta && 'selected'}
                                    >
                                        {kunta}
                                    </option>
                                ))}
                            </Form.Select>
                            {errorMsgs.companyCityError && (
                                <div className="error-message">{errorMsgs.companyCityError}</div>
                            )}
                        </FloatingLabel>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="companyEmail">
                {/* <Form.Label>Sähköposti *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Sähköposti">
                    <Form.Control
                        className={errorMsgs.companyEmailError && 'error'}
                        name="company_email"
                        type="email"
                        placeholder="Sähköposti"
                        required
                        value={data.company_email}
                        onChange={handleChange}
                    />
                    {errorMsgs.companyEmailError && (
                        <div className="error-message">{errorMsgs.companyEmailError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="companyPhone">
                <FloatingLabel controlId="floatingInput" label="Puhelin">
                    <Form.Control
                        className={errorMsgs.companyEmailError && 'error'}
                        name="company_phone"
                        type="tel"
                        placeholder="Puhelin"
                        value={data.company_phone}
                        required
                        onChange={handleChange}
                    />
                    {errorMsgs.companyPhoneError && (
                        <div className="error-message">{errorMsgs.companyPhoneError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="vat">
                {/* <Form.Label>Y-tunnus *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Y-tunnus">
                    <Form.Control
                        className={errorMsgs.vatError && 'error'}
                        name="company_businessid"
                        type="text"
                        required
                        placeholder="Y-tunnus"
                        value={data.company_businessid}
                        onChange={handleChange}
                        disabled={edit ? true : null}
                    />
                    {errorMsgs.vatError && (
                        <div className="error-message">{errorMsgs.vatError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
                {/* <Form.Label>Esittelyteksti</Form.Label> */}
                <FloatingLabel controlId="floatingTextarea" label="Esittelyteksti">
                    <Form.Control
                        as="textarea"
                        // rows="7"
                        name="company_description"
                        placeholder="Esittelyteksti"
                        value={data.company_description}
                        onChange={handleChange}
                        style={{ height: '200px' }}
                    />
                    {errorMsgs.companyDescriptionError && (
                        <div className="error-message">{errorMsgs.companyDescriptionError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>

            <h2 className="mb-4 mt-5">Yhteyshenkilö</h2>

            <Form.Group className="mb-3" controlId="personName">
                {/* <Form.Label>Nimi *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Nimi">
                    <Form.Control
                        className={errorMsgs.personNameError && 'error'}
                        name="user_name"
                        type="text"
                        placeholder="Nimi"
                        required
                        value={data.user_name}
                        onChange={handleChange}
                    />
                    {errorMsgs.personNameError && (
                        <div className="error-message">{errorMsgs.personNameError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="personEmail">
                {/* <Form.Label>Sähköposti *</Form.Label> */}
                <FloatingLabel controlId="floatingInput" label="Sähköposti">
                    <Form.Control
                        className={errorMsgs.personEmailError && 'error'}
                        name="user_email"
                        type="text"
                        required
                        placeholder="Sähköposti"
                        value={data.user_email}
                        onChange={handleChange}
                        disabled={edit ? true : null}
                    />
                    {errorMsgs.personEmailError && (
                        <div className="error-message">{errorMsgs.personEmailError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="personPhone">
                <FloatingLabel controlId="floatingInput" label="Puhelinnumero">
                    <Form.Control
                        className={errorMsgs.personPhoneError && 'error'}
                        name="user_phone"
                        type="tel"
                        required
                        value={data.user_phone}
                        placeholder="Puhelinnumero"
                        onChange={handleChange}
                    />
                    {errorMsgs.personPhoneError && (
                        <div className="error-message">{errorMsgs.personPhoneError}</div>
                    )}
                </FloatingLabel>
            </Form.Group>
            {!edit && (
                <>
                    <InputGroup className="mb-2">
                        <FloatingLabel
                            controlId="floatingPassword"
                            label="Salasana"
                            className="floating-password"
                        >
                            <Form.Control
                                className={errorMsgs.personPasswordError && 'error'}
                                controlId="personPassword"
                                placeholder="Salasana"
                                name="user_password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="username"
                                onChange={handleChange}
                            />
                        </FloatingLabel>
                        <InputGroup.Text>
                            <img
                                className="toggle-password-icon"
                                type="button"
                                alt="näytä salasana"
                                onClick={togglePasswordVisibility}
                                src={showPassword ? showPass : hidePass}
                            />
                        </InputGroup.Text>
                    </InputGroup>
                    {errorMsgs.personPasswordError && (
                        <div className="mb-3 error-message">{errorMsgs.personPasswordError}</div>
                    )}
                </>
            )}

            <Button variant="secondary" className="mb-3" type="submit">
                {edit ? 'Tallenna muutokset' : 'Rekisteröidy'}
            </Button>
            <ToastContainer className="relative">
                <Toast
                    show={showFormErrors}
                    onClose={toggleShowFormErrors}
                    className=" mb-3"
                    animation
                >
                    <Toast.Header className="danger">
                        <strong className="me-auto text-primary">Rekisteröinti epäonnistui</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <p>Korjaa seuraavat virheet:</p>
                        <ul>
                            {Object.keys(errorMsgs).map((key) => {
                                return <li>{errorMsgs[key]}</li>;
                            })}
                        </ul>
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            <Link to="/">
                <Button variant="warning" className="mb-3">
                    Peruuta
                </Button>
            </Link>
            <Form.Group>
                {/* Register error messages */}
                <ToastContainer>
                    <Toast show={showSuccess} onClose={toggleShowSuccess} animation>
                        <Toast.Header className="success">
                            <strong className="me-auto text-primary">Rekisteröinti onnistui</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Aktivoi käyttäjätilisi sähköpostiin saapuvasta linkistä
                        </Toast.Body>
                    </Toast>

                    <Toast show={showError} onClose={toggleShowError} animation>
                        <Toast.Header className="danger">
                            <strong className="me-auto text-primary">
                                {edit ? 'Tallentaminen' : 'Rekisteröinti'} epäonnistui
                            </strong>
                        </Toast.Header>
                        <Toast.Body>Yhteyshenkilö tai yritys on jo rekisteröity</Toast.Body>
                    </Toast>
                </ToastContainer>
            </Form.Group>
        </Form>
    );
};

RegisterForm.propTypes = {
    edit: PropTypes.bool,
    userdata: PropTypes.object,
};
RegisterForm.defaultProps = {
    edit: false,
    userdata: {},
};

export default RegisterForm;
