import { Button, Form, FloatingLabel } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import '../../css/Forms.scss';
import spinner from '../../img/spinner.svg';

const ForgotPasswordForm = () => {
    const [formData, setFormData] = useState({ email: '' });
    const [formSent, setFormSent] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({});
    const [loading, setLoading] = useState(false);

    /** Try to login by posting user credentials to the /login endpoint.
     * If user is found, the endpoint return the information about the user
     * and an authorization token
     * @return {object} User object
     */
    function resetPassword() {
        const data = {
            email: formData.email,
        };
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
        };
        setLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/password/email`, requestOptions)
            .then((res) => {
                setFormSent(true);
                setLoading(false);
            })

            .catch((error) => console.log(error));
    }

    /** Validate login form */
    const validate = () => {
        let errors = false;
        setErrorMsgs({});
        if (!formData.email) {
            setErrorMsgs((prevState) => ({ ...prevState, emailError: 'Täytä sähköpostiosoite' }));
            errors = true;
        }
        return !errors;
    };

    /** Handle form submit event */
    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            resetPassword();
        }
    };

    if (formSent) {
        return (
            <p>
                Jos sähköposti on rekisteröity, salasanan nollauslinkki on lähetetty kyseiseen
                sähköpostiin
            </p>
        );
    }

    if (loading) {
        return <img src={spinner} alt="Ladataan" className="loading-spinner" />;
    }

    return (
        <Form className="mx-auto login-form" onSubmit={submitHandler}>
            <h1>Salasanan palautus</h1>
            <p>
                Kirjoita sähköpostiosoite, jolle tunnus on rekisteröity. Lähetämme osoitteeseen
                palautuslinkin, jolla voit asettaa uuden salasanan
            </p>
            {errorMsgs.emailError && <p>Anna sähköpostiosoite</p>}
            <Form.Group className="mb-3" controlId="email">
                <FloatingLabel label="Sähköposti">
                    <Form.Control
                        className={errorMsgs.emailError && 'error'}
                        placeholder="Sähköpostiosoite"
                        type="email"
                        autoComplete="username"
                        onChange={(e) => {
                            const val = e.target.value;
                            setFormData((prevState) => ({ ...prevState, email: val }));
                        }}
                    />
                </FloatingLabel>
            </Form.Group>
            <Button
                variant={formData.email.length > 0 && 'success'}
                disabled={!(formData.email.length > 0)}
                type="submit"
            >
                Pyydä uusi salasana
            </Button>
        </Form>
    );
};

export default ForgotPasswordForm;
