import { useState } from 'react';
import SearchPopup from './Searchpage/Popup';
import ProductList from './Searchpage/ProductList';
import SearchArea from './Searchpage/SearchArea';
import '../css/Searchpage.scss';

const Searchpage = () => {
    const filtersObject = {
        category: [],
        manufacturer: [],
        location: [],
    };
    const [active, setActive] = useState(false);
    const [products, setProducts] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState(filtersObject);
    const [filteredProductCount, setFilteredProductCount] = useState(0);
    const [productsSelected, setProductsSelected] = useState([]);

    // When the next button is clicked, open popup
    const fetchNext = (singleProducts) => {
        setActive(true);
        setProductsSelected(singleProducts);
    };

    /**
     * Remove all selected machines when the reservation is sent succesfully
     */
    const clearSelections = () => {
        setProductsSelected([]);
    };

    // console.log(filters);
    return (
        <>
            <SearchArea
                amount={filteredProductCount}
                setSearchTerm={setSearchTerm}
                filters={filters}
                setFilters={setFilters}
                variation="products"
            />
            <ProductList
                filters={filters}
                setFilters={setFilters}
                searchTerm={searchTerm}
                sendNext={fetchNext}
                products={products}
                setProducts={setProducts}
                productsSelected={productsSelected}
                setProductsSelected={setProductsSelected}
                setFilteredProductCount={setFilteredProductCount}
            />
            <SearchPopup
                active={active}
                products={productsSelected}
                setActive={setActive}
                clearSelections={clearSelections}
            />
        </>
    );
};

export default Searchpage;
