import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import SingleProduct from './SingleProduct';

/**
 * Used to list all the products fetched from the backend
 */
const ProductList = ({
    sendNext,
    searchTerm,
    filters,
    products,
    setProducts,
    productsSelected,
    setProductsSelected,
    setFilteredProductCount,
}) => {
    /* States */
    // const [productsSelected, setProductsSelected] = useState([]);
    // const [products, setProducts] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const { category, location, manufacturer } = filters;
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const history = useHistory();

    /* Get products from the API */
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/products?filter=status:1`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return history.push('/kirjaudu?action=logout');
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((data) => {
                setProducts(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                console.log(error);
            });
    }, [token, isError]);

    const activeFilterCount = () => {
        let count = 0;
        Object.keys(filters).forEach((item) => {
            count += filters[item].length;
        });
        return count;
    };

    /**
     * When a SingleProduct is selected, the product object is added to
     * the ProductList state
     * @param {object} isSelected
     */
    const itemSelected = (isSelected) => {
        setProductsSelected((prevState) => {
            setProductsSelected(prevState.concat(isSelected));
            return productsSelected;
        });
    };

    /**
     * When a SingleProduct is unselected, remove the product object from the
     * ProductList state by filtering with the id
     * @param {string | number} id
     */
    const itemUnselected = (id) => {
        setProductsSelected(productsSelected.filter((item) => item.id !== id));
    };

    const openPopup = () => {
        if (productsSelected) {
            sendNext(productsSelected);
        }
    };
    const productsSelectedAmount = productsSelected.length;

    /**
     * Loop through product data and filter only those products that matches the searchTerm
     * @param {object} product
     */
    const filteredProducts = () => {
        // const allProducts = products.data;
        const filterByCategory = products.data.filter((product) => {
            if (category.length > 0) {
                return category.some((i) =>
                    product.taxonomies['product-categories']?.some(
                        (productCategory) => productCategory.name === i
                    )
                );
            }
            return product;
        });
        const filterByManufacturer = filterByCategory.filter((product) => {
            if (manufacturer.length > 0) {
                return manufacturer.some((i) =>
                    product.taxonomies.manufacturers?.some((manu) => manu.name === i)
                );
            }
            return product;
        });
        const filterByLocation = filterByManufacturer.filter((product) => {
            if (location.length > 0) {
                return location.some((i) =>
                    product.taxonomies.regions?.some((region) => region.name === i)
                );
            }
            return product;
        });

        /**
         * Combine filter arrays to one and remove duplicates if some filter is set.
         * Otherwise return empty array.
         */
        const outputFilters = [...new Set([...filterByLocation])];

        const filterAllBySearchword = products.data.filter(
            (product) =>
                product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.taxonomies.manufacturers?.some((manu) =>
                    manu.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
        );
        const filterOutputBySearchword = outputFilters.filter(
            (product) =>
                product.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.taxonomies.manufacturers?.some((manu) =>
                    manu.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
        );
        if (searchTerm !== '' && activeFilterCount() !== 0) {
            // console.log('searchterm and some filter are set');
            setFilteredProductCount(filterOutputBySearchword.length);
            return filterOutputBySearchword;
        }
        if (activeFilterCount() !== 0) {
            // console.log('some filter is set');
            setFilteredProductCount(outputFilters.length);
            return outputFilters;
        }
        // console.log('filters are empty');
        setFilteredProductCount(filterAllBySearchword.length);
        return filterAllBySearchword;
    };

    if (isLoading) {
        return (
            <div className="centered-container">
                <h1>Ladataan koneita...</h1>
                <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    if (isError) {
        return (
            <div className="centered-container">
                <h1>Tapahtui virhe, yritä myöhemmin uudelleen</h1>
            </div>
        );
    }
    if (filteredProducts().length === 0) {
        return (
            <div className="centered-container">
                <h1>Yhtään tuotetta ei löytynyt</h1>
            </div>
        );
    }
    if (products) {
        return (
            <Row className="products-list">
                <div className="products-list__columns">
                    <div className="product-name">Koneen nimi</div>
                    <div className="company-name">Yritys</div>
                    <div className="product-year">Vuosimalli</div>
                    <div className="company-location">Sijainti</div>
                </div>
                {filteredProducts().map((product) => (
                    <SingleProduct
                        key={product.id}
                        result={product}
                        isSelected={itemSelected}
                        isUnselected={itemUnselected}
                        selected={productsSelected.some((item) => item.id === product.id)}
                    />
                ))}
                <div
                    className={
                        productsSelectedAmount > 0
                            ? 'next-button-area next-button-area--active'
                            : 'next-button-area'
                    }
                >
                    <Button
                        variant="success"
                        size="lg"
                        disabled={!(productsSelectedAmount > 0)}
                        onClick={openPopup}
                    >
                        Seuraava ({productsSelectedAmount})
                    </Button>
                </div>
            </Row>
        );
    }
    return null;
};

ProductList.propTypes = {
    /** When products are selected and user clicks the next button, pass the product objects up */
    sendNext: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    products: PropTypes.object,
    setProducts: PropTypes.func.isRequired,
    productsSelected: PropTypes.array.isRequired,
    setProductsSelected: PropTypes.func.isRequired,
    setFilteredProductCount: PropTypes.func.isRequired,
};
ProductList.defaultProps = {
    products: {},
};

export default ProductList;
