import PropTypes from 'prop-types';
import { useState } from 'react';
import { DropdownButton, Button } from 'react-bootstrap';
import DropdownFilter from './DropdownFilter';

const FoundResults = ({
    amount,
    filters,
    setFilters,
    setSearchTerm,
    regionData,
    manufacturerData,
    categoryData,
    variation,
    statuses,
}) => {
    const [activeIncoming, setActiveIncoming] = useState(false);
    const [activeOutgoing, setActiveOutgoing] = useState(false);
    const setIncomingFilter = () => {
        if (activeIncoming) {
            setActiveIncoming(false);
            setFilters({ ...filters, reservedFrom: [] });
        } else {
            setActiveIncoming(true);
            setActiveOutgoing(false);
            setFilters({ ...filters, reservedFrom: [true] });
        }
    };
    const setOutgoingFilter = () => {
        if (activeOutgoing) {
            setActiveOutgoing(false);
            setFilters({ ...filters, reservedFrom: [] });
        } else {
            setActiveIncoming(false);
            setActiveOutgoing(true);
            setFilters({ ...filters, reservedFrom: [false] });
        }
    };
    const DesktopFilters = () => {
        if (variation === 'reservations') {
            return (
                <>
                    <DropdownButton id="dropdown-status" title="Status" variant="text">
                        <DropdownFilter
                            data={statuses}
                            name="status"
                            filters={filters}
                            setFilters={setFilters}
                            variation={variation}
                        />
                    </DropdownButton>
                    <Button
                        onClick={setOutgoingFilter}
                        className={activeOutgoing && 'active'}
                        variant="link"
                    >
                        Lähtevät
                    </Button>
                    <Button
                        onClick={setIncomingFilter}
                        className={activeIncoming && 'active'}
                        variant="link"
                    >
                        Saapuvat
                    </Button>
                </>
            );
        }
        return (
            <>
                <DropdownButton id="dropdown-category" title="Kategoria" variant="text">
                    <DropdownFilter
                        data={categoryData}
                        name="category"
                        filters={filters}
                        setFilters={setFilters}
                    />
                </DropdownButton>
                <DropdownButton id="dropdown-category" title="Valmistaja" variant="text">
                    <DropdownFilter
                        data={manufacturerData}
                        name="manufacturer"
                        filters={filters}
                        setFilters={setFilters}
                    />
                </DropdownButton>
                <DropdownButton id="dropdown-category" title="Sijainti" variant="text">
                    <DropdownFilter
                        data={regionData}
                        name="location"
                        filters={filters}
                        setFilters={setFilters}
                    />
                </DropdownButton>
            </>
        );
    };
    return (
        <div className={`found-results found-results--${variation}`}>
            <div className="desktop-filters">
                <DesktopFilters />
            </div>
            <div className="found-results__text">
                Löytyi {amount} {amount === 1 ? 'tulos' : 'tulosta'}
            </div>
            <div className="desktop-search">
                <input
                    className="search-input"
                    type="text"
                    placeholder="Hae nimellä"
                    onChange={(event) => setSearchTerm(event.target.value)}
                />
            </div>
        </div>
    );
};

FoundResults.propTypes = {
    amount: PropTypes.number.isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    regionData: PropTypes.array,
    manufacturerData: PropTypes.array,
    categoryData: PropTypes.array,
    setSearchTerm: PropTypes.func.isRequired,
    variation: PropTypes.string,
    statuses: PropTypes.array,
};
FoundResults.defaultProps = {
    regionData: [],
    manufacturerData: [],
    categoryData: [],
    variation: '',
    statuses: [],
};

export default FoundResults;
