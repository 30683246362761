import { useState, useEffect } from 'react';
// import { Toast } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useQuery } from './Hooks/Hooks'; // Import useQuery hook to extract URL query strings
import LoginForm from './Forms/LoginForm';
import FrontpageGraphic from './Global/FrontpageGraphic';
import ForgotPasswordForm from './Forms/ForgotPasswordForm';
import NewPasswordForm from './Forms/NewPasswordForm';

/** Page template for login and password reset */
const Login = ({ variant, handleLogOut }) => {
    const query = useQuery();
    const action = query.get('action');
    const [didLogout, setDidLogout] = useState(false); // State used to track if backend logout was succesful
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const { token } = cookies;

    // Wrap logout request with useEffect to
    useEffect(() => {
        if (action === 'logout' && token && !didLogout) {
            // Reset auth token in the backend
            fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                if (res.ok || res.status >= 200) {
                    // Remove token from cookies and clear company data from localstorage
                    setDidLogout(true);
                    removeCookie('token', {
                        path: '/',
                    });
                    window.localStorage.clear();
                    handleLogOut();
                }
            });
        }
    }, [didLogout]);

    if (didLogout && !token) {
        console.log('logged out');
        return (
            <>
                <FrontpageGraphic />
                <LoginForm setCookie={setCookie} />
            </>
        );
    }
    // If user is logged in, redirect to frontpage
    if (!didLogout && token) {
        return <Redirect to="/" />;
    }


    if (variant === 'newPassword') {
        return (
            <div className="login-container">
                <NewPasswordForm />
            </div>
        );
    }

    return (
        <div className="login-container">
            {variant === 'reset' ? (
                <ForgotPasswordForm />
            ) : (
                <>
                    <FrontpageGraphic />
                    <LoginForm setCookie={setCookie} />
                </>
            )}
        </div>
    );
};
Login.propTypes = {
    /** Controls the content that is show on page */
    variant: PropTypes.oneOf(['reset', 'newPassword', 'login']),
};
Login.defaultProps = {
    variant: 'login',
};

export default Login;
