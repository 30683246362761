import { Modal } from 'react-bootstrap';

const ImageModal = ({ image, handleModalClose, show, setShow }) => {
    return (
        <Modal className="image-modal" show={show} onHide={handleModalClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Liitetiedosto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={image} alt="Liitetiedosto" />
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;
