import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const renderReactDom = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Root />
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (window.cordova) {
    document.addEventListener(
        'deviceready',
        () => {
            cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
                console.log("You'll get foreground notifications when a push message arrives");
            });
            cordova.plugins.firebase.messaging.onMessage(function(payload) {
                console.log("New foreground FCM message: ", payload);
            });
            cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {
                console.log("New background FCM message: ", payload);
            });

            renderReactDom();
        },
        false
    );
} else {
    renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
