import {
    Row,
    Col,
    Container,
    Accordion,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useState, useEffect } from 'react';
// import { useCookies } from 'react-cookie';
// import { Redirect } from 'react-router-dom';
import { statuses } from './Hooks/Hooks';
import Messages from './Messages';
import ImageModal from './ImageModal';

const ReservationInfo = ({ reservation, edit }) => {
    const [messages, setMessages] = useState(null);
    const [choice, setChoice] = useState(null);
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(null);
    // const [cookies] = useCookies(['token']);
    // const { token } = cookies;
    // const { product } = reservation;
    const billingPeriods = {
        1: 'päivä',
        2: 'viikko',
        3: 'kuukausi',
    };

    const reservationKeys = {
        start_time: 'Alkamis pvm.',
        end_time: 'Päättymis pvm.',
        price: 'Hinta',
        price_split: 'Hinnanjako',
        billing_period: 'Laskutusväli',
    };

    const handleSubmit = () => {
        edit(choice);
    };

    useEffect(() => {
        if (choice) {
            handleSubmit();
        }
    }, [choice]);

    const handleButtons = (e) => {
        setChoice(e.target.value);
    };

    /**
     * Handler function for ImageModal
     */
    const handleModalClose = () => {
        setShow(false);
    };

    /**
     * Listener for message images
     * @param {string} url The image URL
     */
    const onImageClick = (url) => {
        setImage(url);
        setShow(true);
    };

    // Get the messages from the reservation object
    useEffect(() => {
        /* Fetch messages */
        if (reservation) {
            setMessages(reservation.messages);
        }
    }, [reservation]);

    // Check what the user wants to do with the reservation and save the choice to state
    const handleSelection = (e) => {
        setChoice(e.target.value);
    };
    return (
        <>
            <div
                style={{
                    width: '100%',
                    padding: '1em 0',
                    backgroundColor: '#FDC403',
                    fontWeight: '600',
                }}
            >
                <Container>Lisätiedot</Container>
            </div>
            <Container style={{ marginTop: '2em' }} fluid="sm">
                <div className="reservation-info-container">
                    <Row>
                        <Col sm>
                            <p>
                                {reservation.company.id ===
                                parseInt(localStorage.getItem('company_id'), 10)
                                    ? reservation.customer_name
                                    : reservation.company.name}
                            </p>
                            <p>
                                {new Date(reservation.start_time).toLocaleDateString('fi-FI')} -{' '}
                                {new Date(reservation.end_time).toLocaleDateString('fi-FI')}
                            </p>
                            <p>
                                Hinnan jako: {Math.floor(parseFloat(reservation.price_split) * 100)}
                                /{100 - Math.floor(parseFloat(reservation.price_split) * 100)}
                            </p>
                            <p>Varauksen tila: {statuses[reservation.status].name}</p>
                        </Col>
                        <Col sm>
                            <p>
                                {reservation.product.taxonomies?.manufacturers?.[0].name}{' '}
                                {reservation.product.model}
                            </p>
                            <p>
                                Laitteen sijainti:{' '}
                                {reservation.product.taxonomies.regions?.[0].name}
                            </p>
                            <p>
                                {reservation.price}€ / {billingPeriods[reservation.billing_period]}
                            </p>
                            {Object.prototype.hasOwnProperty.call(
                                reservation.taxonomies,
                                'regions'
                            ) && (
                                <p>Varauksen sijainti: {reservation.taxonomies.regions[0].name}</p>
                            )}
                        </Col>
                    </Row>
                </div>
                <hr />
                {/* Display message accordion if messages are found */}
                {messages && messages.length > 0 && (
                    <>
                        <Accordion className="comments-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Näytä kommentit ({messages.length})
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Messages
                                        reservation={reservation}
                                        reservationKeys={reservationKeys}
                                        billingPeriods={billingPeriods}
                                        onImageClick={onImageClick}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <hr />
                        <ImageModal
                            image={image}
                            handleModalClose={handleModalClose}
                            show={show}
                            setShow={setShow}
                        />
                    </>
                )}
                {(reservation.status === 1 || reservation.status === 4) && (
                    <Form className="radio-container reservation-action-select">
                        <fieldset disabled={reservation.status === 3 || reservation.status === 2}>
                            <Form.Group>
                                {reservation.modified_by_company_id !==
                                    parseInt(localStorage.getItem('company_id'), 10) && (
                                    <Form.Check
                                        type="radio"
                                        name="group"
                                        className="radio--accept"
                                        label="Hyväksy varaus"
                                        value="accept"
                                        onChange={handleSelection}
                                    />
                                )}
                                <Form.Check
                                    type="radio"
                                    name="group"
                                    className="radio--remove"
                                    label="Hylkää ja poista varaus"
                                    value="remove"
                                    onChange={handleSelection}
                                />
                                <Form.Check
                                    type="radio"
                                    name="group"
                                    className="radio--comment"
                                    label="Kommentoi ja muuta varauksen tietoja"
                                    value="comment"
                                    onChange={handleSelection}
                                />
                            </Form.Group>
                        </fieldset>
                    </Form>
                )}
                {reservation.status === 3 && reservation.reserved_from && (
                    <div
                        className="button-container"
                        style={{ width: '100%', textAlign: 'center', marginTop: '3em' }}
                    >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Kun olet lähettänyt tuotteen vastaanottajalle, muista merkitä se
                                    tästä
                                </Tooltip>
                            }
                        >
                            <Button
                                onClick={handleButtons}
                                value="delivered"
                                style={{ padding: '1em 2em', width: '100%', marginBottom: '1em' }}
                            >
                                Merkitse toimitetuksi
                            </Button>
                        </OverlayTrigger>
                    </div>
                )}
                {reservation.status === 2 && !reservation.reserved_from && (
                    <div
                        className="button-container"
                        style={{ width: '100%', textAlign: 'center', marginTop: '3em' }}
                    >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Kun olet palauttanut tuotteen lähettäjälle, muista merkitä se
                                    tästä
                                </Tooltip>
                            }
                        >
                            <Button
                                onClick={handleButtons}
                                value="returned"
                                style={{ padding: '1em 2em' }}
                            >
                                Merkitse palautetuksi
                            </Button>
                        </OverlayTrigger>
                    </div>
                )}
                {/* Show "Lisää kommentti" button in all stages */}
                {![1, 4].includes(reservation.status) && (
                    <div
                        className="button-container"
                        style={{ width: '100%', textAlign: 'center' }}
                    >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Voit lisätä varaukseen kommentteja ja liitetiedostoja tästä
                                </Tooltip>
                            }
                        >
                            <Button
                                onClick={handleButtons}
                                variant="warning"
                                value="addNote"
                                style={{ padding: '1em 2em', width: '100%' }}
                            >
                                Lisää kommentti tai liitetiedosto
                            </Button>
                        </OverlayTrigger>
                    </div>
                )}
                <div className="reservation-button-container" style={{ textAlign: 'center' }}>
                    {choice === 'accept' && (
                        <Button
                            className="reservation-submit-button"
                            variant="success"
                            onClick={handleSubmit}
                        >
                            Hyväksy varaus
                        </Button>
                    )}
                    {choice === 'remove' && (
                        <Button
                            className="reservation-submit-button"
                            variant="danger"
                            onClick={handleSubmit}
                        >
                            Hylkää ja poista
                        </Button>
                    )}
                    {choice === 'comment' && (
                        <Button
                            className="reservation-submit-button"
                            variant="warning"
                            onClick={handleSubmit}
                        >
                            Kommentoi ja muuta varauksen tietoja
                        </Button>
                    )}
                </div>
            </Container>
        </>
    );
};

ReservationInfo.propTypes = {
    reservation: PropTypes.object,
    edit: PropTypes.func.isRequired,
};
ReservationInfo.defaultProps = {
    reservation: {},
};

export default ReservationInfo;
