import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import AddMachineForm from './Forms/AddMachineForm';
import AddCSVForm from './Forms/AddCSVForm';

const AddMachine = ({ setPopupActive }) => {
    const [addManually, setAddManually] = useState(false);
    const [loading, setLoading] = useState(false);
    const [added, setAdded] = useState(false);
    const [fileSent, setFileSent] = useState(false);
    const [addCSV, setAddCSV] = useState(false);
    const [csvUploadErrors, setCsvUploadErrors] = useState(null);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    /**
     * Clear popup when the user cancel machine adding stage
     */
    const actionCancelled = () => {
        setAddManually(false);
        setAddCSV(false);
    };

    /**
     * Add the machine with the data object received from AddMachineForm.js
     * @param {object} data
     */
    const addMachine = (data) => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/products`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((res) => {
                setAdded(true);
            })
            .catch((error) => console.log(error));
    };

    /**
     * Upload the CSV file to the backend server
     * @param {object} formData 
     */
    const uploadCSV = (formData) => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/products/upload`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((res) => {
                if (res[0] && res[0].match(/no file was uploaded/i)) {
                    console.log('errors found');
                    setCsvUploadErrors('Tiedoston lähetys epäonnistui, yritä myöhemmin uudelleen');
                }
                setAddCSV(false);
                setFileSent(true);
            })
            .catch((error) => console.log(error));
    };

    if (loading) {
        return (
            <div
                className="container text-center"
                style={{
                    transform: 'translate(50%, -50%)',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    width: '100%',
                }}
            >
                <Spinner animation="border" role="status" variant="primary" size="lg">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (added) {
        return (
            <div
                className="container text-center"
                style={{
                    transform: 'translate(50%, -50%)',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    width: '100%',
                }}
            >
                <h1 className="mb-5">Laite lisätty</h1>
                <Button
                    onClick={() => setPopupActive(false)}
                    variant="success"
                    className="mb-4 w-100 p-3"
                >
                    Takaisin konekantaan
                </Button>
            </div>
        );
    }

    if (fileSent) {
        return (
            <div
                className="container text-center"
                style={{
                    transform: 'translate(50%, -50%)',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    width: '100%',
                }}
            >
                <h1 className="mb-5">Laitteet lisätty</h1>
                <Button
                    onClick={() => setPopupActive(false)}
                    variant="success"
                    className="mb-4 w-100 p-3"
                >
                    Takaisin konekantaan
                </Button>
            </div>
        );
    }

    if (addManually) {
        return <AddMachineForm cancel={actionCancelled} addMachine={addMachine} />;
    }

    if (addCSV) {
        return <AddCSVForm cancel={actionCancelled} uploadCSV={uploadCSV} errors={csvUploadErrors} />;
    }

    return (
        <div className="add-machine-popup-container">
            <h1>Koneen lisäys</h1>

            <h1 style={{ fontWeight: 'bold', fontSize: '2.5rem', marginTop: 'auto' }}>
                Valitse koneen <br />
                lisäystapa:
            </h1>
            <div className="button-area d-grid gap-3">
                <Button
                    className="btn btn-success btn-lg"
                    type="button"
                    onClick={() => setAddCSV(true)}
                >
                    CSV
                </Button>
                <Button
                    className="btn btn-success btn-lg"
                    onClick={() => setAddManually(true)}
                    type="button"
                >
                    Manuaalinen
                </Button>
                <Button
                    onClick={() => setPopupActive(false)}
                    className="btn btn-warning btn-lg"
                    type="button"
                >
                    Peruuta
                </Button>
            </div>
        </div>
    );
};

export default AddMachine;
