import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/** Component for a single product, used in ProductList.js */
const SingleProduct = ({ result, isSelected, isUnselected, selected }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [selection, setSelection] = useState(false);
    const [selectionData, setSelectionData] = useState();
    // Check if the product is current user's company's own product
    let isOwnProduct = false;
    if (parseInt(localStorage.getItem('company_id'), 10) === result.company.id) {
        isOwnProduct = true;
    }

    // Show product's additional information
    const showExtraInfo = () => {
        setShowInfo((prevCheck) => !prevCheck);
    };

    /**
     * If no other product is selected, allow selection.
     * If product is selected and "Select" button is clicked again, remove selection.
     */
    const onSelection = () => {
        setSelection((prevCheck) => !prevCheck);
        setSelectionData(result);
    };

    useEffect(() => {
        if (selected) {
            setSelection(true);
            setSelectionData(result);
        } else {
            setSelection(false);
        }
    }, [selected]);

    /**
     * Wait for selection state to update before passing it up to ProductList.js
     */
    useEffect(() => {
        if (selection && !selected) {
            isSelected(selectionData);
        } else if (!selection && selectionData) {
            isUnselected(selectionData.id);
        }
    }, [selection]);

    return (
        <div className={selection || selected ? 'single-item selected' : 'single-item'}>
            <div
                className={`item-details ${result.additional_info ? 'has-extra-info' : ''} ${
                    showInfo ? 'open' : 'closed'
                }`}
                onClick={showExtraInfo}
                onKeyPress={showExtraInfo}
                role="button"
                tabIndex={0}
            >
                {/* If product has extra details, add chevron arrow with open and closed classes */}
                <div className="item-name">
                    {result.taxonomies.manufacturers?.[0].name} {result.model}
                </div>
                <div className={`company-name ${isOwnProduct ? 'own-product' : ''}`}>
                    {result.company.name}
                </div>
                <div className="product-year">{result.year}</div>
                <div className="company-location">{result.taxonomies.regions?.[0].name}</div>
            </div>
            <Button
                onClick={onSelection}
                disabled={isOwnProduct}
                variant={selection ? 'success' : 'primary'}
            >
                {selection ? 'Valittu' : 'Valitse'}
            </Button>
            {result.additional_info ? (
                <div className={`extra-details ${showInfo ? 'show' : ''}`}>
                    <div className="wrapper">{result.additional_info}</div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

SingleProduct.propTypes = {
    /** Object that contains the information about the product */
    result: PropTypes.object.isRequired,
    /** Function, that returns the selected event back to parent */
    isSelected: PropTypes.func.isRequired,
    /** Function, that returns the unselected event back to parent */
    isUnselected: PropTypes.func.isRequired,
    selected: PropTypes.func.isRequired,
};

export default SingleProduct;
