import PropTypes from 'prop-types';
import { Container, Offcanvas } from 'react-bootstrap';

const Popup = ({ active, setActive, content, className }) => {
    // const handleClose = () => {
    //     closePopup();
    // };

    // If the "X" symbol in the popup is clicked, close popup
    const closePopup = () => setActive(false);

    return (
        // <div className={active ? 'popup active' : 'popup'}>
        //     <div className="popup-inner">
        //         <img className="close-icon" src={closeIcon} alt="Sulje" onClick={handleClose} />

        //         <Container>{content}</Container>
        //     </div>
        // </div>
        <Offcanvas
            className={`offcanvas-popup ${className}`}
            show={active}
            onHide={closePopup}
            placement="bottom"
        >
            <Offcanvas.Header closeButton />
            <Offcanvas.Body>
                <Container>{content}</Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

Popup.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
    className: PropTypes.string,
};
Popup.defaultProps = {
    className: '',
};

export default Popup;
