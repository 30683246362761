import React from "react";

function NotFound() {

    return (
        <div className="error404">
            <h1>
                404 Page not found
            </h1>
        </div>
    );
}

export default NotFound;
