import { Link } from 'react-router-dom';
import userLogo from '../../../img/user-light.svg';
import ActionPointBadge from '../ActionPointBadge';

const UserMenuItems = () => {
    return (
        <>
            <Link to="/profiili" className="user-menu-link nav-link">
                <img src={userLogo} alt="Oma sivu" style={{ height: '35px' }} />
                <ActionPointBadge />
            </Link>
        </>
    );
};

export default UserMenuItems;
