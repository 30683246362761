import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import RentForm from '../Forms/RentForm';
import Popup from '../Global/Popup';
import RentConfirmation from './RentConfirmation';

/** Popup window that scrolls into view when triggered */
const SearchPopup = ({ active, products, setActive, clearSelections }) => {
    const [requestBody, setRequestBody] = useState({});
    const [contractSent, setContractSent] = useState();
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    // Save the product IDs to an array
    const productIDs = [];

    useEffect(() => {
        if (products) {
            products.map((product) => {
                return productIDs.push(product.id);
            });
            setRequestBody({ product_id: productIDs.toString() });
        }
    }, [products]);

    // If the popup is closed and the contract was sent, clear selections
    useEffect(() => {
        if (contractSent && !active) {
            clearSelections();
        }
    }, [active]);

    // Fetch the rent form data to be sent to the server
    const fetchRentForm = (rentFormData) => {
        setRequestBody((prevState) => {
            return {
                ...prevState,
                status: 1,
                start_time: rentFormData.startDate,
                end_time: rentFormData.endDate,
                price: rentFormData.rentPrice,
                location: rentFormData.location,
                additional_info: rentFormData.extraInfo,
                price_split: rentFormData.priceSplit,
                validity: rentFormData.offerValid,
                billing_period: rentFormData.billingInterval,
                attachment: rentFormData.file,
            };
        });
        // setRequestBody({
        //     product_id: productIDs.toString(),
        //     status: 1,
        //     start_time: rentFormData.startDate,
        //     end_time: rentFormData.endDate,
        //     price: rentFormData.rentPrice,
        //     location: rentFormData.location,
        //     additional_info: rentFormData.extraInfo,
        //     price_split: rentFormData.priceSplit,
        //     validity: rentFormData.offerValid,
        //     billing_period: rentFormData.billingInterval,
        // });
    };

    const contractConfirmed = () => {
        fetch(`${process.env.REACT_APP_API_URL}/reservations`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        }).then((res) => {
            if (res.ok) {
                setContractSent(true);
            } else {
                setContractSent(false);
            }
        });
    };

    const contractCanceled = () => {
        setRequestBody({ product_id: productIDs.toString() });
        console.log(productIDs);
    };

    if (products) {
        return (
            <Popup
                active={active}
                setActive={setActive}
                className="offcanvas--initial-reservation"
                content={
                    <>
                        <h1 className="mb-3 text-center">
                            Alustava varaus ({products ? products.length : ''})
                        </h1>
                        <div className="products-container text-center mb-5">
                            {products &&
                                products.map((product) => (
                                    <p className="text-muted product-row" key={product.id}>
                                        {product.model} / {product.company.name} /{' '}
                                        {product.taxonomies.regions[0].name}
                                    </p>
                                ))}
                        </div>

                        {/* If form data is not present, show the form
                        else show confirmation screen */}
                        {Object.keys(requestBody).length <= 1 ? (
                            <RentForm sendForm={fetchRentForm} />
                        ) : (
                            <RentConfirmation
                                submitContract={contractConfirmed}
                                cancelContract={contractCanceled}
                                didSend={contractSent}
                                setActive={setActive}
                            />
                        )}
                    </>
                }
            />
        );
    }
    return '';
};

SearchPopup.propTypes = {
    /** Controls whether the popup is shown or hidden */
    active: PropTypes.bool.isRequired,
    /** Object that contains the selected products information */
    products: PropTypes.array,
    /** If the popup is closed, pass the event up to the parent so the popup can be hidden */
    setActive: PropTypes.func.isRequired,
};
SearchPopup.defaultProps = {
    products: [],
};

export default SearchPopup;
