import PropTypes from 'prop-types';
import { Accordion, Offcanvas, ListGroup, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import filterIcon from '../../img/filters.svg';
import DropdownFilter from '../Global/DropdownFilter';

const Filter = ({
    filters,
    setFilters,
    regionData,
    manufacturerData,
    categoryData,
    variation,
    statuses,
}) => {
    // console.log(filters);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // console.log(regionData);
    const removeSelectedFilter = (event) => {
        const { value, name } = event.target.dataset;
        const currentFilters = filters[name];
        const filterItem = currentFilters.filter((e) => e !== value);
        setFilters({ ...filters, [name]: filterItem });
    };
    // ToDo: Use the same object from parent
    const filtersObject = {
        category: [],
        manufacturer: [],
        location: [],
        status: [],
        reservedFrom: [],
    };
    const removeAllFilters = () => {
        // console.log('filters removed');
        setFilters(filtersObject);
    };

    const activeFilterCount = () => {
        let count = 0;
        Object.keys(filters).forEach((item) => {
            count += filters[item].length;
        });
        return count;
    };

    const handleStatusFilter = (event) => {
        const filterVal = event.target.dataset.value;
        if (filterVal === '0') {
            setFilters({ ...filters, status: [] });
        } else if (!filters.status.includes(filterVal)) {
            setFilters({ ...filters, status: [parseInt(filterVal, 10)] });
        }
    };
    const CurrentFilters = () => {
        if (activeFilterCount() !== 0) {
            return (
                <div className="filters-wrapper">
                    <div className="filters-heading">
                        <button
                            type="button"
                            className="btn btn-link filter-reset"
                            onClick={removeAllFilters}
                        >
                            Tyhjennä valinnat <span>×</span>
                        </button>
                    </div>
                    <div className="active-filters">
                        {Object.keys(filters).map((item) => (
                            <div className="filtertype-wrapper" key={item.toString()}>
                                {filters[item].map((value) => (
                                    <div key={value.toString()} className="filter-badge">
                                        {value}
                                        <button
                                            type="button"
                                            className="filter-badge__remove"
                                            data-name={item}
                                            data-value={value}
                                            onClick={removeSelectedFilter}
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        // console.log('empty array');
        return false;
    };
    return (
        <>
            <button type="button" className="filter-button btn btn-link" onClick={handleShow}>
                <img src={filterIcon} alt="Filter icon" width="40" height="40" />
                {activeFilterCount() !== 0 && (
                    <span className="count-badge count-badge--filters">{activeFilterCount()}</span>
                )}
            </button>
            <Offcanvas
                className="offcanvas--filters"
                show={show}
                onHide={handleClose}
                placement="end"
            >
                <Offcanvas.Header closeButton closeVariant="white">
                    <button
                        type="button"
                        className="btn-close btn-close-back btn-close-white"
                        aria-label="Close"
                        onClick={handleClose}
                    />
                    <Offcanvas.Title>Suodattimet</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {variation === 'reservations' && (
                        <ListGroup variant="flush" className="reservations-filters">
                            {statuses.map((value) => (
                                <Button
                                    className={
                                        (value.id === 0 && filters.status.length === 0) ||
                                        filters.status.includes(value.id)
                                            ? 'active'
                                            : ''
                                    }
                                    key={value.id}
                                    data-value={value.id}
                                    onClick={handleStatusFilter}
                                >
                                    {value.name}
                                </Button>
                            ))}
                        </ListGroup>
                    )}
                    {variation === 'products' || variation === 'machines' ? (
                        <>
                            <CurrentFilters />
                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Kategoria</Accordion.Header>
                                    <Accordion.Body>
                                        <DropdownFilter
                                            data={categoryData}
                                            name="category"
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Valmistaja</Accordion.Header>
                                    <Accordion.Body>
                                        <DropdownFilter
                                            data={manufacturerData}
                                            name="manufacturer"
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Sijainti</Accordion.Header>
                                    <Accordion.Body>
                                        {regionData && (
                                            <DropdownFilter
                                                data={regionData}
                                                name="location"
                                                filters={filters}
                                                setFilters={setFilters}
                                            />
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                    ) : (
                        ''
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

Filter.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    regionData: PropTypes.array,
    manufacturerData: PropTypes.array,
    categoryData: PropTypes.array,
    variation: PropTypes.string,
    statuses: PropTypes.array,
};
Filter.defaultProps = {
    regionData: [],
    manufacturerData: [],
    categoryData: [],
    variation: '',
    statuses: [],
};

export default Filter;
