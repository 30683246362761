import {
    Button,
    Col,
    Form,
    InputGroup,
    Row,
    Toast,
    ToastContainer,
    FloatingLabel,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { timeOptions } from './ContractTimeOptions';
import csvIcon from '../../img/file-csv-solid.svg';

/** Form for making a new rental contract */
const RentForm = ({ sendForm, data, addNote }) => {
    const [formData, setFormData] = useState({});
    const [editTimeLimit, setEditTimeLimit] = useState(false);
    const errorMsgsInitState = {};
    const [errorMsgs, setErrorMsgs] = useState(errorMsgsInitState);
    const [priceSplitDisplay, setPriceSplitDisplay] = useState('30/70');
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const [regionData, setRegionData] = useState();
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [show, setShow] = useState(false);

    let editData = {};
    useEffect(() => {
        if (data) {
            editData = {
                priceSplit: data.price_split,
                startDate: data.start_time,
                endDate: data.end_time,
                rentPrice: data.price,
                billingInterval: data.billing_period,
                location: data.taxonomies.regions[0].id,
                offerValid: data.validity,
            };
            setFormData(editData);
            setPriceSplitDisplay(
                `${Math.floor(editData.priceSplit * 100)}/${
                    100 - parseInt(editData.priceSplit * 100, 10)
                }`
            );
        } else {
            editData = {
                priceSplit: '',
                startDate: '',
                endDate: '',
                rentPrice: '',
                billingInterval: '',
                location: '',
                offerValid: '',
            };
            setFormData({
                priceSplit: 0.3, // Set default price split to avoid NaN in frontend
                offerValid: '12',
                billingInterval: 1,
            });
        }
    }, [data]);

    // Fetch the available regions from the backend
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/regions`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setRegionData(res);
            });
    }, [token]);

    /* Get current date in ISO format to disallow selection of past dates in date fields. */
    const today = new Date().toISOString().split('T')[0];

    /* Toggle offerValid field editability */
    const toggleEditTimeLimit = () => {
        setEditTimeLimit(!editTimeLimit);
    };

    /* Add changing fields to state */
    const handleChange = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        if (key === 'priceSplit') {
            // Calculate the price split
            setFormData((prevState) => {
                return { ...prevState, [key]: val / 100 };
            });
            // Set the price split display text as value / 100 - value
            setPriceSplitDisplay(`${val}/${100 - parseInt(val, 10)}`);
        } else {
            setFormData((prevState) => {
                return { ...prevState, [key]: val };
            });
        }
    };

    /* Validate form fields and set error messages if necessary */
    const validate = () => {
        setErrorMsgs(errorMsgsInitState); // clear error messages
        let errors = false; // track if errors are found

        if (!formData.startDate) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                startDateError: 'Valitse alkamis pvm.',
            }));
            errors = true;
        }
        if (!formData.endDate) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                endDateError: 'Valitse päättymis pvm.',
            }));
            errors = true;
        }
        // Transform start and end dates to milliseconds since epoch for comparison
        const startDate = new Date(formData.startDate).getTime();
        const endDate = new Date(formData.endDate).getTime();

        // If the start date is earlier than the end date, raise an error
        if (startDate > endDate) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                endDateError: 'Päättymispäivä ei voi olla ennen alkamispäivää',
            }));
            errors = true;
        }
        if (!formData.rentPrice) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                rent_priceError: 'Anna hinta',
            }));
            errors = true;
        } else if (!/^\d+$/.test(formData.rentPrice)) {
            // Check if the price contains only numbers
            setErrorMsgs((prevState) => ({
                ...prevState,
                rent_priceError: 'Anna hinta numeroina',
            }));
            errors = true;
        }
        if (!formData.location || formData.location === 'Sijainti') {
            setErrorMsgs((prevState) => ({
                ...prevState,
                locationError: 'Valitse sijainti',
            }));
            errors = true;
        }
        if (file) {
            // If file type is not an image, give an error
            if (!/image\/[a-z]*/.test(file.type)) {
                setErrorMsgs((prevState) => ({
                    ...prevState,
                    fileError: 'Tiedosto ei ole kuvatiedosto',
                }));
                errors = true;
            }
        }

        if (errors) {
            return false;
        }
        return true;
    };

    /* Submit handler */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            sendForm(formData);
        }
    };

    /* FILE UPLOAD METHODS */

    /**
     * Handle uploaded file.
     * Add file URL to data state and add the file object to file state for validation
     * @param {object} e - Event object
     */
    const handleUpload = (e) => {
        setFileError(null);
        if (e.target.files[0]) {
            const uploadedFile = e.target.files[0];
            // Limit file size to 9MB
            if (uploadedFile.size > 9000000) {
                setFileError('Tiedoston maksimikoko on 9MB');
                setShow(true);
                return;
            }
            // // Limit file types to CSV and Excel files
            // if (!uploadedFile.type.match(/csv/i) || !uploadedFile.type.match(/spreadsheetml/i)) {
            //     setFileError('Tiedoston muoto on väärä');
            //     setShow(true);
            //     return;
            // }
            setFile(uploadedFile);
        }
    };

    /**
     * Handle file deletion.
     * Remove the file from the state.
     * @param {object} e - Event object
     */
    const handleFileDelete = (e) => {
        e.preventDefault();
        setFile(null);
        setFormData((prevState) => ({ ...prevState, file: '' }));
    };

    /** When an image is uploaded, convert the blob to Base64 to be saved in the backend */
    useEffect(() => {
        /* Check if the file state is present */
        if (file) {
            const reader = new FileReader();
            const base64Regex = /,(.*)/;
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setFormData((prevState) =>
                    // Use regex to strip the unnecessary characters and leave just the base64 string
                    ({ ...prevState, file: reader.result.match(base64Regex)[1] })
                );
            };
        }
    }, [file]);

    if (addNote) {
        return (
            <Form noValidate className="rent-form add-note" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" style={{ width: '100%' }}>
                    <Form.Control
                        as="textarea"
                        name="extraInfo"
                        rows="3"
                        placeholder="Kommentti..."
                        onChange={handleChange}
                    />
                </Form.Group>
                <ToastContainer className="mb-4">
                    <Toast show={show} onClose={() => setShow(false)} animation bg="danger">
                        <Toast.Header className="danger">
                            <strong className="me-auto text-primary">Virhe</strong>
                        </Toast.Header>
                        <Toast.Body>{fileError}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="file-info">
                    {file && (
                        <span role="button" tabIndex={0} onClick={handleFileDelete}>
                            Close
                        </span>
                    )}
                    <p className="text-muted">{file && file.name}</p>
                </div>
                <label
                    className="btn btn-primary upload-button mb-2"
                    htmlFor="file-upload-field"
                    tabIndex={0}
                    role="button"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M384 352v64c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32v-64c0-17.67-14.33-32-32-32s-32 14.33-32 32v64c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96v-64c0-17.67-14.33-32-32-32S384 334.3 384 352zM201.4 9.375l-128 128c-12.51 12.51-12.49 32.76 0 45.25c12.5 12.5 32.75 12.5 45.25 0L192 109.3V320c0 17.69 14.31 32 32 32s32-14.31 32-32V109.3l73.38 73.38c12.5 12.5 32.75 12.5 45.25 0s12.5-32.75 0-45.25l-128-128C234.1-3.125 213.9-3.125 201.4 9.375z" />
                    </svg>
                    Lisää liitetiedosto
                    <input
                        type="file"
                        id="file-upload-field"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                    />
                </label>
                <p className="text-muted" style={{fontStyle: 'italic'}}>Liitetiedostoiksi käyvät vain kuvatiedostot</p>

                <Button variant="success" type="submit" className="mb-3">
                    Lähetä
                </Button>
            </Form>
        );
    }

    return (
        <Form noValidate className="rent-form" onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col md>
                    <Form.Label>Alkamis pvm.</Form.Label>
                    <Form.Control
                        type="date"
                        name="startDate"
                        placeholder="Alkamis pvm."
                        min={today}
                        onChange={handleChange}
                        defaultValue={formData.startDate}
                    />
                    {errorMsgs.startDateError && (
                        <div className="error-message">{errorMsgs.startDateError}</div>
                    )}
                </Col>
                <Col md>
                    <Form.Label>Arvioitu päät. pvm.</Form.Label>
                    <Form.Control
                        type="date"
                        name="endDate"
                        placeholder="Arvioitu pal. pvm."
                        min={formData.startDate}
                        onChange={handleChange}
                        defaultValue={formData.endDate}
                    />
                    {errorMsgs.endDateError && (
                        <div className="error-message">{errorMsgs.endDateError}</div>
                    )}
                </Col>
            </Row>
            <p>Hinta</p>
            <Row>
                <Col md>
                    <Form.Group className="mb-3">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                name="rentPrice"
                                placeholder="Hinta"
                                onChange={handleChange}
                                defaultValue={formData.rentPrice}
                            />
                            <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup>
                        {errorMsgs.rent_priceError && (
                            <div className="error-message">{errorMsgs.rent_priceError}</div>
                        )}
                    </Form.Group>
                </Col>
                <Col md>
                    <Form.Group className="mb-3">
                        <Form.Select name="billingInterval" onChange={handleChange}>
                            <option selected={formData.billingInterval === 1 && true} value="1">
                                Per päivä
                            </option>
                            <option selected={formData.billingInterval === 2 && true} value="2">
                                Per viikko
                            </option>
                            <option selected={formData.billingInterval === 3 && true} value="3">
                                Per kuukausi
                            </option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mb-3">
                <Form.Label style={{ width: '100%' }}>
                    Hinnanjako
                    <div className="price-split-container">
                        <p>Vuokraajan osuus</p>{' '}
                        <div className="price-split">{priceSplitDisplay}</div>
                        <p>Omistajan osuus</p>
                    </div>
                </Form.Label>
                <Form.Range
                    name="priceSplit"
                    min="0"
                    max="100"
                    step="5"
                    onChange={handleChange}
                    defaultValue={parseInt(formData.priceSplit, 10) * 100 || 30}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                {/* <Form.Select
                    type="text"
                    name="location"
                    placeholder="Sijainti"
                    onChange={handleChange}
                >
                    <option>Sijainti</option>
                    {regionData &&
                        regionData.map((region) => (
                            <option
                                key={region.id}
                                value={region.id}
                                selected={formData.location === region.id}
                            >
                                {region.name}
                            </option>
                        ))}
                </Form.Select> */}
                <FloatingLabel label="Sijainti" controlId="floatingInput">
                    <Select
                        className="select-container"
                        classNamePrefix="select"
                        value={
                            (regionData &&
                                formData.location &&
                                regionData.filter((region) => region.id === formData.location)) ||
                            null
                        }
                        isClearable={false}
                        isSearchable="true"
                        name="location"
                        placeholder=""
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={regionData}
                        onChange={(e) => {
                            setFormData((prevState) => ({
                                ...prevState,
                                location: e === null ? '' : e.id,
                            }));
                        }}
                    />
                </FloatingLabel>
                {errorMsgs.locationError && (
                    <div className="error-message">{errorMsgs.locationError}</div>
                )}
            </Form.Group>
            <p>Varaus voimassa (oletus 1 päivä)</p>
            <Form.Group className="mb-3">
                <InputGroup>
                    <Form.Select
                        disabled={!editTimeLimit}
                        type="text"
                        name="offerValid"
                        placeholder="Varaus voimassa (oletus 1 päivä)"
                        onChange={handleChange}
                    >
                        {timeOptions}
                    </Form.Select>
                    {!editTimeLimit && (
                        <InputGroup.Text className={editTimeLimit ? 'active' : 'disabled'}>
                            <a href="#" onClick={toggleEditTimeLimit}>
                                Muuta aikaa
                            </a>
                        </InputGroup.Text>
                    )}
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    name="extraInfo"
                    rows="3"
                    placeholder="Muut huomiot (Rahti, ehdot ym)..."
                    onChange={handleChange}
                />
            </Form.Group>
            <div className="file-info">
                {file && (
                    <span role="button" tabIndex={0} onClick={handleFileDelete}>
                        Close
                    </span>
                )}
                <p className="text-muted">{file && file.name}</p>
            </div>
            {errorMsgs.fileError && <div className="error-message mb-3">{errorMsgs.fileError}</div>}
            <label
                className="btn btn-primary upload-button mb-2"
                htmlFor="file-upload-field"
                tabIndex={0}
                role="button"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M384 352v64c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32v-64c0-17.67-14.33-32-32-32s-32 14.33-32 32v64c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96v-64c0-17.67-14.33-32-32-32S384 334.3 384 352zM201.4 9.375l-128 128c-12.51 12.51-12.49 32.76 0 45.25c12.5 12.5 32.75 12.5 45.25 0L192 109.3V320c0 17.69 14.31 32 32 32s32-14.31 32-32V109.3l73.38 73.38c12.5 12.5 32.75 12.5 45.25 0s12.5-32.75 0-45.25l-128-128C234.1-3.125 213.9-3.125 201.4 9.375z" />
                </svg>
                Lisää liitetiedosto
                <input
                    type="file"
                    id="file-upload-field"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleUpload}
                />
            </label>
            <p className="text-muted" style={{fontStyle: 'italic'}}>Liitetiedostoiksi käyvät vain kuvatiedostot</p>
            <Button variant="success" type="submit" className="mb-3">
                Lähetä
            </Button>
        </Form>
    );
};

RentForm.propTypes = {
    /** Callback function to fetch form data from the form */
    sendForm: PropTypes.func.isRequired,
    data: PropTypes.object,
    addNote: PropTypes.string,
};
RentForm.defaultProps = {
    data: null,
    addNote: null,
};

export default RentForm;
