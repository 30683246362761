import { React, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Popup from './Global/Popup';
import RegisterForm from './Forms/RegisterForm';

const ProfileInfo = () => {
    // Popup state
    const [active, setActive] = useState(false);
    const openPopup = () => setActive(true);

    // Get login data from local storage
    const logo = localStorage.getItem('company_logo');
    const name = localStorage.getItem('company_name');
    const email = localStorage.getItem('company_email');
    const phone = localStorage.getItem('company_phone');
    const description = localStorage.getItem('company_description');
    const streetaddress = localStorage.getItem('company_streetaddress');
    const zip = localStorage.getItem('company_zip');
    const postal = localStorage.getItem('company_postal');
    const businessid = localStorage.getItem('company_businessid');
    const username = localStorage.getItem('user_name');
    const userEmail = localStorage.getItem('user_email');
    const userPhone = localStorage.getItem('user_phone');

    const userData = {
        company_logo: logo,
        company_name: name,
        company_email: email,
        company_phone: phone,
        company_description: description,
        company_streetaddress: streetaddress,
        company_zip: zip,
        company_postal: postal,
        company_businessid: businessid,
        user_name: username,
        user_email: userEmail,
        user_phone: userPhone,
    };

    return (
        <>
            <Container className="profileinfo-container">
                <Row className="profileinfo-image">
                    <Col>
                        <div
                            className="profile-image"
                            style={{ backgroundImage: `url(${logo})` }}
                        />
                    </Col>
                </Row>
                <Row className="profileinfo-company">
                    <Col>
                        <h5 className="section-title company-name">{name}</h5>
                        <div className="address">
                            {streetaddress} <br />
                            {zip} {postal}
                        </div>
                        <div className="email">{email}</div>
                        <div className="phone">{phone}</div>
                        <div className="vat">{businessid}</div>
                        <div className="description">{description}</div>
                    </Col>
                </Row>
                <Row className="profileinfo-user">
                    <Col>
                        <h5 className="section-title user-name">{username}</h5>
                        <div className="email">{userEmail}</div>
                        <div className="phone">{userPhone}</div>
                    </Col>
                </Row>

                <Button className="btn-lg edit-btn" variant="warning" onClick={openPopup}>
                    Muokkaa
                </Button>
            </Container>
            <Popup active={active} setActive={setActive} content={<RegisterForm edit userdata={userData} />} />
        </>
    );
};

export default ProfileInfo;
