import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * Todo:
 * - add selected filters to url parameters (and cache?)
 * - move active list items to top of the list (optional)
 */

const DropdownFilter = ({ data, name, filters, setFilters, variation }) => {
    const [filteredData, setFilteredData] = useState(data); // set default value so the list wouldn't be empty without a searchword

    const handleList = (event) => {
        const searchWord = event.target.value;
        const newFilter = data.filter((value) =>
            value.name.toLowerCase().includes(searchWord.toLowerCase())
        );
        setFilteredData(newFilter);
    };

    const currentFilters = filters[name];

    const removeSelectedFilter = (removableItem) => {
        const filterItem = currentFilters.filter((value) => value !== removableItem);
        setFilters({ ...filters, [name]: filterItem });
    };

    const handleFilter = (event) => {
        const filterVal = event.target.dataset.value;
        if (currentFilters.includes(filterVal)) {
            removeSelectedFilter(filterVal);
        } else {
            setFilters({ ...filters, [name]: [...currentFilters, filterVal] });
        }
    };

    const handleStatusFilter = (event) => {
        const filterVal = event.target.dataset.value;
        if (filterVal === '0') {
            setFilters({ ...filters, status: [] });
        } else if (!filters.status.includes(filterVal)) {
            setFilters({ ...filters, status: [parseInt(filterVal, 10)] });
        }
    };

    return (
        <div className="dropdown-filter">
            {variation !== 'reservations' && (
                <input
                    className="search-input form-control"
                    type="text"
                    placeholder="Hae listasta"
                    onChange={handleList}
                />
            )}
            {filteredData.length !== 0 && (
                <div className="results">
                    <ul className="results-list">
                        {
                            // Sort data alphabetically by name before mapping
                            filteredData
                                .sort((a, b) =>
                                    variation === 'reservations'
                                        ? a.id - b.id
                                        : a.name.localeCompare(b.name)
                                )
                                .map((value) => (
                                    <li key={value.id}>
                                        <button
                                            className={
                                                (variation === 'reservations' &&
                                                    value.id === 0 &&
                                                    currentFilters.length === 0) ||
                                                currentFilters.includes(
                                                    variation === 'reservations'
                                                        ? value.id
                                                        : value.name
                                                )
                                                    ? 'dropdown-filter__button active'
                                                    : 'dropdown-filter__button'
                                            }
                                            type="button"
                                            data-value={
                                                variation === 'reservations'
                                                    ? `${value.id}`
                                                    : `${value.name}`
                                            }
                                            data-id={value.id}
                                            onClick={
                                                variation === 'reservations'
                                                    ? handleStatusFilter
                                                    : handleFilter
                                            }
                                        >
                                            {value.name}
                                        </button>
                                    </li>
                                ))
                        }
                    </ul>
                </div>
            )}
        </div>
    );
};

DropdownFilter.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    data: PropTypes.array,
    name: PropTypes.string.isRequired,
    variation: PropTypes.string,
};
DropdownFilter.defaultProps = {
    data: [],
    variation: '',
};

export default DropdownFilter;
