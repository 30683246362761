import Logo from '../../../img/veksla_logo_no_text.svg';

const HeaderLogo = () => {
    return (
        <img
            src={Logo}
            alt="Veksla logo"
            className="d-inline-block align-top"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
    );
};

export default HeaderLogo;
