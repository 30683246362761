const options = [];
var i;
for (i = 24; i <= 96; i += 24) {
    options.push(i);
}
export const timeOptions = options.map((item) => {
    return <option value={item} key={item}>{item / 24} päivä{item !== 24 && "ä"}</option>;
});


