import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase config for push notifications
const firebaseConfig = {
    apiKey: "AIzaSyA3ApF-F5LiIeOmBZUhfYh0VUwXfwt5u9E",
    authDomain: "veksla-356f4.firebaseapp.com",
    projectId: "veksla-356f4",
    storageBucket: "veksla-356f4.appspot.com",
    messagingSenderId: "874305924758",
    appId: "1:874305924758:web:220554d026e38f84ac9cb7"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

// eslint-disable-next-line import/prefer-default-export
export const retrieveToken = (setTokenFound, setNotificationToken) => {

        if (window.cordova) {
            cordova.plugins.firebase.messaging.getToken().then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken);
                    setTokenFound(true);
                    setNotificationToken(currentToken);
                } else {
                    setTokenFound(false);
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        } else {

            getToken(messaging, {
                vapidKey:
                    'BLUelSmLJOSZNJt9T6m6xlFPR8zubHa6n9LRc5sQITBNBoArnpDrUdtds4T53OZ2jUvQm1fGxupJW-oC-bPO8Wo',
            })
                .then((currentToken) => {
                    if (currentToken) {
                        setTokenFound(true);
                        setNotificationToken(currentToken);
                    } else {
                        setTokenFound(false);
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });



        }
};

export const onMessageListener = () => {
    onMessage(messaging, (payload) => {
        console.log('Message received: ', payload);
    });
};
