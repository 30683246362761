import { Button, Form, InputGroup, FloatingLabel } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import '../../css/Forms.scss';
import spinner from '../../img/spinner.svg';
import showPass from '../../img/eye-regular.svg';
import hidePass from '../../img/eye-slash-regular.svg';
import { saveDataToLocalStorage } from '../Hooks/Hooks';

const LoginForm = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loginError, setLoginError] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({});
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(['token']);
    const [token, setToken] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    // When token is retrieved from the backend, set it to the cookie
    // useEffect prevents an infinite loop bug
    useEffect(() => {
        if (token) {
            setCookie('token', token, {
                path: '/',
            });
        }
    }, [token]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /** Try to login by posting user credentials to the /login endpoint.
     * If user is found, the endpoint return the information about the user
     * and an authorization token
     * @return {object} User object
     */
    function loginUser() {
        const data = {
            email: formData.email,
            password: formData.password,
        };
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                },
        };
        setLoading(true);
        setLoginError(false);

        fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.access_token) {
                    setToken(result.access_token);
                    saveDataToLocalStorage(result);
                    setLoading(false);
                } else {
                    setErrorMsgs((prevState) => ({
                        ...prevState,
                        loginError: 'Käyttäjätunnus tai salasana väärin, yritä uudelleen',
                    }));
                    setLoading(false);
                }
            })
            .catch((error) => console.log(error));
    }

    /** Validate login form */
    const validate = () => {
        let errors = false;
        setErrorMsgs({});
        if (!formData.email) {
            setErrorMsgs((prevState) => ({ ...prevState, emailError: 'Täytä sähköpostiosoite' }));
            errors = true;
        }
        if (!formData.password) {
            setErrorMsgs((prevState) => ({ ...prevState, passwordError: 'Täytä salasana' }));
            errors = true;
        }
        return !errors;
    };

    /** Handle form submit event */
    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            loginUser();
        }
    };

    if (token && !loading) {
        return <Redirect to="/" />;
    }

    if (!loading) {
        return (
            <Form className="mx-auto login-form" onSubmit={submitHandler}>
                {loginError && <p>Käyttäjätunnus tai salasana väärin</p>}
                <Form.Group className="mb-3" controlId="email">
                <FloatingLabel label="Sähköpostiosoite">
                    <Form.Control
                        className={errorMsgs.emailError && 'error'}
                        placeholder="Sähköpostiosoite"
                        type="email"
                        autoComplete="username"
                        onChange={(e) => {
                            const val = e.target.value;
                            setFormData((prevState) => ({ ...prevState, email: val }));
                        }}
                    />
                    </FloatingLabel>
                </Form.Group>
                <InputGroup className="mb-2">
                    <Form.Control
                        className={errorMsgs.passwordError && 'error'}
                        placeholder="Salasana"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="username"
                        onChange={(e) => {
                            const val = e.target.value;
                            setFormData((prevState) => ({ ...prevState, password: val }));
                        }}
                    />
                    <InputGroup.Text>
                        <img
                            className="toggle-password-icon"
                            type="button"
                            alt="Näytä salasana"
                            onClick={togglePasswordVisibility}
                            src={showPassword ? showPass : hidePass}
                        />
                    </InputGroup.Text>
                </InputGroup>
                <div className="error-messages mb-3">
                    {errorMsgs &&
                        Object.keys(errorMsgs).map((error) => (
                            <div className="error-message">{errorMsgs[error]}</div>
                        ))}
                </div>

                <Button
                    variant={formData.email.length > 0 && formData.password.length > 0 && 'success'}
                    disabled={!(formData.email.length > 0 && formData.password.length > 0)}
                    type="submit"
                >
                    Kirjaudu sisään
                </Button>
                <Link
                    style={{ float: 'right', margin: '1em', fontSize: '12px' }}
                    to="/salasanan-palautus"
                >
                    Unohtuiko salasana?
                </Link>
            </Form>
        );
    }
    return <img src={spinner} alt="Ladataan" className="loading-spinner" />;
};

export default LoginForm;
