import { useEffect, useState } from 'react';
import { statuses } from './Hooks/Hooks';
import ImageModal from './ImageModal';

const Messages = ({ reservation, reservationKeys, billingPeriods, onImageClick }) => {
    const [messages, setMessages] = useState(null);

    /**
     * Check if the message date is the same as the current date.
     *
     * @param {date} messageDate The date from the message
     * @returns {boolean} Returns true, if the dates match
     */
    const isMessageDateToday = (messageDate) => {
        const today = new Date();
        const msgDate = new Date(messageDate);
        return (
            msgDate.getDate() == today.getDate() &&
            msgDate.getMonth() == today.getMonth() &&
            msgDate.getFullYear() == today.getFullYear()
        );
    };
    /**
     * Get the messages from the reservation object
     */
    useEffect(() => {
        if (reservation) {
            setMessages(reservation.messages);
        }
    }, [reservation]);
    return (
        messages &&
        messages.map((message, messageIndex) => {
            // Check if the message was sent today
            const isToday = isMessageDateToday(message.created_at);
            return (
                <>
                    <div className="message" key={message.id}>
                        <p
                            style={{
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                marginBottom: '0',
                            }}
                        >
                            {parseInt(localStorage.getItem('company_id'), 10) ===
                            message.from_company
                                ? 'Minä'
                                : reservation.company.name}
                        </p>
                        <p
                            className="text-muted"
                            style={{
                                fontSize: '.8rem',
                                fontStyle: 'italic',
                            }}
                        >
                            {/* Create a timestamp for the message */}
                            {isToday
                                ? 'Tänään klo ' +
                                  new Date(message.created_at).toLocaleString('fi-FI', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })
                                : new Date(message.created_at).toLocaleString('fi-FI', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                  })}
                        </p>
                        {/* Create a message when the reservation status changes */}
                        {message.history.status && (
                            <p>
                                Varauksen tila on muuttunut:{' '}
                                {statuses[message.history.status[0]].name} →{' '}
                                {statuses[message.history.status[1]].name}
                            </p>
                        )}
                        {/* List the changes that were made to the reservation since the previous message */}
                        {Object.keys(message.history).some((key) => key !== 'status') && (
                            <>
                                <p>Varauksen ehdoissa on muutoksia:</p>
                                <ul>
                                    {Object.keys(message.history).length > 0 &&
                                        Object.keys(message.history).map((key) => {
                                            if (key === 'status') {
                                                return false;
                                            }
                                            if (key === 'start_time' || key === 'end_time') {
                                                return (
                                                    <li key={key}>
                                                        {reservationKeys[key]}:{' '}
                                                        {new Date(
                                                            message.history[key][0]
                                                        ).toLocaleDateString()}{' '}
                                                        →{' '}
                                                        {new Date(
                                                            message.history[key][1]
                                                        ).toLocaleDateString()}
                                                    </li>
                                                );
                                            }
                                            if (key === 'price_split') {
                                                return (
                                                    <li key={key}>
                                                        {reservationKeys[key]}:{' '}
                                                        {Math.floor(
                                                            parseFloat(message.history[key][0]) *
                                                                100
                                                        )}
                                                        /
                                                        {100 -
                                                            Math.floor(
                                                                parseFloat(
                                                                    message.history[key][0] * 100
                                                                )
                                                            )}{' '}
                                                        →{' '}
                                                        {Math.floor(
                                                            parseFloat(message.history[key][1]) *
                                                                100
                                                        )}
                                                        /
                                                        {100 -
                                                            Math.floor(
                                                                parseFloat(
                                                                    message.history[key][1] * 100
                                                                )
                                                            )}
                                                    </li>
                                                );
                                            }
                                            if (key === 'billing_period') {
                                                return (
                                                    <li key={key}>
                                                        {reservationKeys[key]}:{' '}
                                                        {billingPeriods[message.history[key][0]]} →{' '}
                                                        {billingPeriods[message.history[key][1]]}{' '}
                                                    </li>
                                                );
                                            }
                                            return (
                                                <li key={key}>
                                                    {reservationKeys[key]}:{' '}
                                                    {message.history[key][0]} →{' '}
                                                    {message.history[key][1]}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </>
                        )}
                        {message.message && <p>Kommentti: {message.message}</p>}
                        {message.attachment && (
                            <div className="attachments">
                                <p style={{ fontWeight: 'bold' }}>Liitetiedosto:</p>
                                <div
                                    className="attachment-file-container"
                                    onClick={() => onImageClick(message.attachment)}
                                >
                                    <img src={message.attachment_thumbnail} alt="Liitetiedosto" />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        })
    );
};

export default Messages;
