import { Offcanvas } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReservationInfo from './ReservationInfo';
import Popup from './Global/Popup';
import EditReservation from './EditReservation';

const ReservationInfoSidebar = ({ show, setShow, reservationInfo }) => {
    const [popupActive, setPopupActive] = useState(false);
    const [selection, setSelection] = useState(null);
    const [reservation, setReservation] = useState(false);

    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    useEffect(() => {
        if (reservationInfo) {
            setReservation(reservationInfo);
        }
    }, [reservationInfo]);
    useEffect(() => {
        if (!popupActive && reservation) {
            reservationEdited(reservation.id);
        }
    }, [popupActive]);

    const reservationEdited = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/reservations/${id}`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => setReservation(data.data))
            .catch((err) => console.log(err));
    };

    const handleClose = () => {
        setShow(false);
    };

    const PopupContent = () => {
        return (
            <EditReservation
                setShow={setShow}
                data={reservation}
                variant={selection}
                showPopup={setPopupActive}
                reservationEdited={reservationEdited}
            />
        );
    };

    const editReservation = (variant) => {
        setPopupActive(true);
        setSelection(variant);
    };
    return (
        <>
            <Offcanvas
                className="offcanvas--reservation"
                onHide={handleClose}
                show={show}
                placement="end"
                style={{ width: '100%' }}
            >
                <Offcanvas.Header closeButton closeVariant="white">
                    <button
                        type="button"
                        className="btn-close btn-close-back btn-close-white"
                        aria-label="Close"
                        onClick={handleClose}
                    />
                    <Offcanvas.Title>Varauksen tiedot</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {reservation && (
                        <ReservationInfo reservation={reservation} edit={editReservation} />
                    )}
                </Offcanvas.Body>
            </Offcanvas>
            <Popup active={popupActive} setActive={setPopupActive} content={<PopupContent />} />
        </>
    );
};

export default ReservationInfoSidebar;
