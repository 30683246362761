import PropTypes from 'prop-types';
import { useState } from 'react';
import ItemsList from './ItemsList';
import SearchArea from './Searchpage/SearchArea';

const ListPage = ({ variation }) => {
    const filtersObject = {
        category: [],
        manufacturer: [],
        location: [],
        status: [],
        reservedFrom: [],
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState(filtersObject);
    const [filteredProductCount, setFilteredProductCount] = useState(0);
    const [items, setItems] = useState();

    // console.log(variation);

    return (
        <div className={`listpage-container listpage-container--${variation}`}>
            <SearchArea
                amount={filteredProductCount}
                setSearchTerm={setSearchTerm}
                filters={filters}
                setFilters={setFilters}
                variation={variation}
            />
            <ItemsList
                items={items}
                setItems={setItems}
                filters={filters}
                searchTerm={searchTerm}
                variation={variation}
                setFilteredProductCount={setFilteredProductCount}
            />
        </div>
    );
};

ListPage.propTypes = {
    variation: PropTypes.string.isRequired,
};
// ListPage.defaultProps = {
//     bookings: {},
// };

export default ListPage;
