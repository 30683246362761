/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/self-closing-comp */ // Exceptions for empty option-tags inside select boxes
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';

const AddMachineForm = ({ cancel, addMachine, item, removeMachine, setPopupActive }) => {
    const [errorMsgs, setErrorMsgs] = useState({});
    const [data, setData] = useState({ archived: 0 });
    const [regionData, setRegionData] = useState(null);
    const [categories, setCategories] = useState(null);
    const [manufacturers, setManufacturers] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const currentTime = new Date();

    /** Add form field values to state */
    const handleChange = (e) => {
        // console.log(e);
        const key = e.target.name;
        const val = e.target.value;
        setData((prevState) => ({ ...prevState, [key]: val }));
    };
    /** Different handler for Select components as their target is a bit different */
    // const handleSelectChange = (e) => {
    //     // console.log(e);
    //     const key = e?.name || null;
    //     const val = e?.value || null;
    //     setData((prevState) => ({ ...prevState, [key]: val }));
    // };

    const remove = () => {
        removeMachine(item.id);
    };

    useEffect(() => {
        if (item) {
            // eslint-disable-next-line camelcase
            const { additional_info, model, year, status } = item;
            const location = item.taxonomies?.regions ? item.taxonomies.regions[0].id : null;
            const manufacturer = item.taxonomies?.manufacturers
                ? item.taxonomies.manufacturers[0].id
                : null;
            const category = item.taxonomies?.['product-categories']
                ? item.taxonomies['product-categories'][0].id
                : null;

            setData({
                model,
                manufacturer,
                year,
                status,
                category,
                additional_info,
                location,
            });
        } else {
            setData({ status: 1 });
        }
    }, []);

    /**
     * Validate form fields
     * @returns {boolean} Errors
     */
    const validate = () => {
        setErrorMsgs({});
        let errors = false;

        if (!data.model) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                modelError: 'Lisää laitteen malli',
            }));
            errors = true;
        }
        // if (!data.manufacturer) {
        //     setErrorMsgs((prevState) => ({
        //         ...prevState,
        //         manufacturerError: 'Lisää laitteen valmistaja',
        //     }));
        //     errors = true;
        // }
        // if (!data.year) {
        //     setErrorMsgs((prevState) => ({
        //         ...prevState,
        //         yearError: 'Lisää laitteen vuosimalli',
        //     }));
        //     errors = true;
        // }
        if (data.year < 1900 || data.year > currentTime.getFullYear()) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                yearError: `Vuosimalli ei voi olla pienempi kuin 1900 tai suurempi kuin ${currentTime.getFullYear()}`,
            }));
            errors = true;
        }
        // if (!data.location) {
        //     setErrorMsgs((prevState) => ({
        //         ...prevState,
        //         locationError: 'Valitse sijainti',
        //     }));
        //     errors = true;
        // }
        // if (!data.category) {
        //     setErrorMsgs((prevState) => ({
        //         ...prevState,
        //         categoryError: 'Valitse kategoria',
        //     }));
        //     errors = true;
        // }
        if (!data.status && data.status !== 0) {
            setErrorMsgs((prevState) => ({
                ...prevState,
                statusError: 'Valitse status',
            }));
            errors = true;
        }
        return !errors;
    };

    // Handle form when submitted
    const submitHandler = (e) => {
        e.preventDefault();
        if (validate()) {
            addMachine(data);
        }
    };

    // Fetch the available regions from the backend
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/regions`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setRegionData(res);
            });

        fetch(`${process.env.REACT_APP_API_URL}/categories/children/product-categories`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setCategories(res);
            });
        fetch(`${process.env.REACT_APP_API_URL}/categories/children/manufacturers`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            // .then((res) => console.log(res))
            .then((res) => {
                setManufacturers(res);
            });
    }, [token]);

    // Remove machine confirmation content
    if (confirm) {
        return (
            <div className="add-machine-popup-container">
                <h1>Haluatko varmasti poistaa laitteen?</h1>
                <div className="button-area">
                    <Button onClick={remove} variant="danger" className="mb-4">
                        Kyllä
                    </Button>
                    <Button variant="success" className="mb-4" onClick={() => setConfirm(false)}>
                        Peruuta
                    </Button>
                </div>
            </div>
        );
    }

    // Machine form content
    return (
        <Form className="add-machine-form" noValidate onSubmit={submitHandler}>
            <h2>Koneen tiedot</h2>

            <Form.Group className="mb-3" controlId="machineModel">
                <FloatingLabel label="Koneen malli" controlId="floatingInput">
                    <Form.Control
                        className={errorMsgs.modelError && 'error'}
                        name="model"
                        type="text"
                        placeholder="Koneen malli"
                        value={data.model}
                        required
                        onChange={handleChange}
                    />
                </FloatingLabel>
                {errorMsgs.modelError && (
                    <div className="error-message">{errorMsgs.modelError}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel label="Valmistaja" controlId="floatingSelect">
                    {/* <Form.Select
                        name="manufacturer"
                        value={data?.manufacturer}
                        defaultValue={data?.manufacturer || null}
                        onChange={handleChange}
                    >
                        <option value={null}></option>
                        {manufacturers &&
                            manufacturers.map((manufacturer) => (
                                <option key={manufacturer.id} value={manufacturer.id}>
                                    {manufacturer.name}
                                </option>
                            ))}
                    </Form.Select> */}
                    <Select
                        className="select-container"
                        classNamePrefix="select"
                        value={
                            (manufacturers &&
                                data.manufacturer &&
                                manufacturers.filter(
                                    (manufacturer) => manufacturer.id === data.manufacturer
                                )) ||
                            null
                        }
                        isClearable="true"
                        isSearchable="true"
                        name="manufacturer"
                        placeholder=""
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={manufacturers}
                        onChange={(e) => {
                            // console.log(e);
                            setData((prevState) => ({ ...prevState, manufacturer: e === null ? '' : e.id }));
                        }}
                    />
                </FloatingLabel>
                {errorMsgs.manufacturerError && (
                    <div className="error-message">{errorMsgs.manufacturerError}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel label="Vuosimalli" controlId="floatingInput">
                    <Form.Control
                        type="number"
                        min="1950"
                        max={new Date().getFullYear()}
                        name="year"
                        placeholder="1985"
                        value={data.year}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                {errorMsgs.yearError && <div className="error-message">{errorMsgs.yearError}</div>}
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel label="Sijainti" controlId="floatingSelect">
                    {/* <Form.Select
                        name="location"
                        value={data?.location}
                        defaultValue={data?.location || null}
                        onChange={handleChange}
                    >
                        <option value={null}></option>
                        {regionData &&
                            regionData.map((region) => (
                                <option
                                    key={region.id}
                                    value={region.id}
                                    selected={data.location === region.name && true}
                                >
                                    {region.name}
                                </option>
                            ))}
                    </Form.Select> */}
                    <Select
                        className="select-container"
                        classNamePrefix="select"
                        // defaultValue={data?.location || null}
                        value={
                            (regionData &&
                                data.location &&
                                regionData.filter((location) => location.id === data.location)) ||
                            null
                        }
                        isClearable="true"
                        isSearchable="true"
                        name="location"
                        placeholder=""
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={regionData}
                        onChange={(e) => {
                            // console.log(e);
                            setData((prevState) => ({ ...prevState, location: e === null ? '' : e.id }));
                        }}
                    />
                </FloatingLabel>
                {errorMsgs.locationError && (
                    <div className="error-message">{errorMsgs.locationError}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel controlId="floatingSelect" label="Kategoria">
                    {/* <Form.Select
                        name="category"
                        value={data?.category}
                        defaultValue={data?.category || null}
                        onChange={handleChange}
                    >
                        <option value={null}></option>
                        {categories &&
                            categories.map((category) => (
                                <option
                                    key={category.id}
                                    value={category.id}
                                    selected={data.category === category.name && 'selected'}
                                >
                                    {category.name}
                                </option>
                            ))}
                    </Form.Select> */}
                    <Select
                        className="select-container"
                        classNamePrefix="select"
                        // defaultValue={data?.category || null}
                        value={
                            (categories &&
                                data.category &&
                                categories.filter((category) => category.id === data.category)) ||
                            null
                        }
                        isClearable="true"
                        isSearchable="true"
                        name="category"
                        placeholder=""
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={categories}
                        onChange={(e) => {
                            // console.log(e);
                            setData((prevState) => ({ ...prevState, category: e === null ? '' : e.id }));
                        }}
                    />
                </FloatingLabel>
                {errorMsgs.categoryError && (
                    <div className="error-message">{errorMsgs.categoryError}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <FloatingLabel label="Koneen tila" controlId="floatingSelect">
                    <Form.Select
                        name="status"
                        value={data?.status}
                        defaultValue="1"
                        onChange={handleChange}
                    >
                        <option selected={data.status === 1 && 'selected'} value="1">
                            Varattavissa
                        </option>
                        <option selected={data.status === 0 && 'selected'} value="0">
                            Ei varattavissa
                        </option>
                    </Form.Select>
                </FloatingLabel>
                {errorMsgs.statusError && (
                    <div className="error-message">{errorMsgs.statusError}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-5">
                <FloatingLabel label="Lisätiedot..." controlId="floatingTextarea">
                    <Form.Control
                        as="textarea"
                        name="additional_info"
                        placeholder="Lisätiedot..."
                        onChange={handleChange}
                        value={data.additional_info}
                        rows="7"
                    />
                </FloatingLabel>
            </Form.Group>
            <Button type="submit" variant="success" className="mb-3">
                {item ? 'Tallenna' : 'Lisää'}
            </Button>
            {item && (
                <Button variant="danger" className="mb-3" onClick={() => setConfirm(true)}>
                    Poista kone
                </Button>
            )}
            <Button
                variant="warning"
                className="mb-3"
                onClick={!item ? cancel : () => setPopupActive(false)}
            >
                Peruuta
            </Button>
        </Form>
    );
};

AddMachineForm.propTypes = {
    /** Function for cancelling the form returning back */
    cancel: PropTypes.func.isRequired,
    /** Returns the form data to the parent element */
    addMachine: PropTypes.func,
    removeMachine: PropTypes.func,
    setPopupActive: PropTypes.func.isRequired,
    /** Object that contains the product data */
    item: PropTypes.object,
};
AddMachineForm.defaultProps = {
    item: null,
};

export default AddMachineForm;
