import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';
import AddMachineForm from './Forms/AddMachineForm';

const EditMachine = ({ item, setPopupActive }) => {
    const [loading, setLoading] = useState(false);
    const [added, setAdded] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;

    /**
     * Remove selected machine
     *
     * @param {number} int - Product ID
     */
    const removeMachine = (productID) => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/products/${productID}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then(() => setRemoved(true))
            .catch((error) => console.log(error));
    };

    /**
     * Edit the selected product details
     * @param {object} body - Request body
     */
    const edit = (body) => {
        fetch(`${process.env.REACT_APP_API_URL}/products/${item.id}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                setLoading(false);
                if (res.status === 401) {
                    return <Redirect to="/kirjaudu?action=logout" />;
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((res) => {
                setAdded(true);
            })
            .catch((error) => console.log(error));
    };

    if (loading) {
        return (
            <div
                className="container text-center"
                style={{
                    transform: 'translate(50%, -50%)',
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    width: '100%',
                }}
            >
                <Spinner animation="border" role="status" variant="primary" size="lg">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (added) {
        return (
            <>
                <h1 className="mb-5 text-center">Laiteen tiedot tallennettu</h1>
                <div className="button-area">
                    <Button
                        onClick={() => setPopupActive(false)}
                        variant="success"
                        className="mb-4 w-100 p-3"
                    >
                        Takaisin konekantaan
                    </Button>
                </div>
            </>
        );
    }

    if (removed) {
        return (
            <>
                <h1 className="mb-5 text-center">Laite poistettu</h1>
                <div className="button-area">
                    <Button
                        onClick={() => setPopupActive(false)}
                        variant="success"
                        className="mb-4 w-100 p-3"
                    >
                        Takaisin konekantaan
                    </Button>
                </div>
            </>
        );
    }

    return (
        <AddMachineForm
            item={item}
            addMachine={edit}
            removeMachine={removeMachine}
            setPopupActive={setPopupActive}
        />
    );
};

export default EditMachine;

EditMachine.propTypes = {
    /** Object that contains the product data to be edited */
    item: PropTypes.object.isRequired,
    /** Function to control the popup visibility */
    setPopupActive: PropTypes.func.isRequired,
};
