import { Col } from 'react-bootstrap';
import logo from '../../img/veksla_logo_vertical.svg';

const FrontpageGraphic = () => {
    return (
        <Col xs={12}>
            <img src={logo} alt="Veksla logo" />
            {/* <p className="text-center text-muted">Versio: {process.env.REACT_APP_VER}</p> */}
        </Col>
    );
};

export default FrontpageGraphic;
