import { useLocation } from 'react-router';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Status array for reservations
export const statuses = [
    { id: 0, name: 'Kaikki', slug: 'all' },
    { id: 1, name: 'Uusi varaus', slug: 'new' },
    { id: 2, name: 'Toimitettu', slug: 'delivered' },
    { id: 3, name: 'Hyväksytty', slug: 'approved' },
    { id: 4, name: 'Kesken', slug: 'wip' },
    { id: 5, name: 'Odottaa vastausta', slug: 'waiting' },
    { id: 6, name: 'Peruutettu', slug: 'canceled' },
    { id: 7, name: 'Arkistoitu', slug: 'archived' },
];

export function saveDataToLocalStorage(result) {
    localStorage.setItem('user_name', result.name);
    localStorage.setItem('user_email', result.email);
    localStorage.setItem('user_phone', result.phone);
    localStorage.setItem('user_id', result.user_id);
    /* Map through company object and save details to local storage */
    Object.keys(result.company).map((key) =>
        localStorage.setItem(`company_${key}`, result.company[key])
    );
}

/**
 * Hook for setInterval
 * @param {*} callback
 * @param {*} delay
 */
export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return '';
    }, [delay]);
}

/**
 * Global fetch hook that we can use to fetch data from our API
 * @param {*} query
 * @returns status, data
 */
export const useFetch = (query) => {
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [cookies] = useCookies(['token']);
    const { token } = cookies;
    const history = useHistory();

    useEffect(() => {
        // console.log(query);
        if (!query) return;
        // const fetchData = async () => {
        setStatus('loading');
            // setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/${query}`, {
            headers: {
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    return history.push('/kirjaudu?action=logout');
                }
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then((data) => {
                // setIsLoading(false);
                setData(data);
                setStatus('loaded');
            })
            .catch((error) => {
                // setError(true);
                // setIsLoading(false);
                setStatus('error');
                console.log(error);
            });
            // const newData = await response.json();
            // setData(newData);
            // setStatus('fetched');
        // };
        // fetchData();
    }, [query]);

    return { data, status };
};
